import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

// Create async thunk for fetching the article by ID
export const fetchArticleById = createAsyncThunk(
  'article/fetchById',
  async (articleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${URL}/articale/${articleId}`);
      return response.data;  // Assuming the data is returned directly
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching article');
    }
  }
);

const articleSlice = createSlice({
  name: 'article',
  initialState: {
    article: null,  // Store the fetched article data here
    status: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticleById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticleById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.article = action.payload;  // Assuming the entire payload is the article
      })
      .addCase(fetchArticleById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload?.msg || action.payload || 'Failed to fetch article';
      });
  },
});

export default articleSlice.reducer;
