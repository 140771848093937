// src/component/InstructorCourse/InstructorCourse.js

import React, { useEffect } from 'react';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import Saved from '../../assets/images/Course/CourseSaved.png';
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorCoursesById } from '../../store/userFeature/showCoursesByInstructorIdSlice'; // Updated import
import Loading from '../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import './instructorCourse.css'
const InstructorCourse = ({ instructorId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access instructor-specific courses from the Redux store
  const { list: courses, status, error } = useSelector((state) => state.instructorCoursesById);

  useEffect(() => {
    if (instructorId) {
      dispatch(fetchInstructorCoursesById(instructorId));
    }
  }, [dispatch, instructorId]);

  // Handle navigation to course details
  const handleCourseClick = (id) => {
    navigate(`/coursedetails/${id}`);
  };

  if (status === 'loading') return <Loading />;
  if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <div className="InstructorCourses">
      {courses.length === 0 ? (
        <p>No courses found for this instructor.</p>
      ) : (
        courses.map((course) => (
          <div
            className="Course_Container"
            key={course.id}
            onClick={() => handleCourseClick(course.id)}
          >
            <div>
              {/* Section 1: Course Image */}
              <div className="Course_Sec1">
                <img className="Course_Sec1" src={course.image} alt="CourseImg" />
              </div>

              {/* Section 2: Start Date */}
              <div className="Course_Sec2">Start {course.start_date ? new Date(course.start_date).toLocaleDateString() : 'TBA'}</div>

              {/* Section 3: Course Title */}
              <div className="Course_Sec3">{course.title}</div>

              {/* Section 4: Course Hours and Sessions */}
              <div className="Course_Sec4">
                <img src={Clock} alt="Clock" /> {course.course_hours} &nbsp; ({course.session_count} sessions)
              </div>

              {/* Section 5: Seats and Ratings */}
              <div className="Course_Sec5">
                <img src={Chair} alt="Chair" /> {course.seats_count} seats left
                <img src={Line} alt="Line" />
                <img src={Start} alt="Star" /> {course.rate ? course.rate : '95%'} (234)
              </div>

              {/* Section 6: Category */}
              <div className="Course_Sec6" style={{ paddingTop: '8px', color: 'blue' }}>
                {course.category.title}
              </div>

              {/* Section 7: Instructor Name */}
              <div className="Course_Sec6">
                {course.instructor.name}
              </div>

              {/* Section 8: Action Icons */}
              <div className="Course_Sec7_Home_guest">
                <div>
                  <img src={Saved} alt="Saved" />
                </div>
                <div>
                  <img src={AddToCart} alt="Add to Cart" />
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default InstructorCourse;