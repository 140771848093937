import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // استيراد الـ URL من config
import Cookies from 'js-cookie';

// جلب بيانات السلة للمستخدم
export const fetchUserShowCart = createAsyncThunk(
  'cart/fetchUserShowCart',
  async (_, { rejectWithValue }) => {
    try {
      const Token = Cookies.get('authToken'); // جلب التوكن من الـ cookies
      const config = {
        headers: {
          token: Token, // إرسال التوكن في الهيدر
        },
      };

      const response = await axios.get(`${URL}/user-courses-citems`, config); // جلب بيانات السلة من الـ API
      return response.data.data.courses; // استخراج الدورات من البيانات
    } catch (err) {
      return rejectWithValue(err.response?.data || 'Error fetching cart data'); // التعامل مع الأخطاء
    }
  }
);

const userShowCartSlice = createSlice({
  name: 'userShowCart',
  initialState: {
    courses: [], // حالة افتراضية للمصفوفة
    status: 'idle', // الحالة الأولية
    error: null, // حالة الخطأ
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserShowCart.pending, (state) => {
        state.status = 'loading'; // عندما يكون الطلب جارٍ
        state.error = null;
      })
      .addCase(fetchUserShowCart.fulfilled, (state, action) => {
        state.status = 'succeeded'; // عندما ينجح الطلب
        state.courses = action.payload || []; // تحديث الدورات في الـ state
      })
      .addCase(fetchUserShowCart.rejected, (state, action) => {
        state.status = 'failed'; // عندما يفشل الطلب
        state.error = action.payload || 'Failed to load cart data'; // تخزين رسالة الخطأ
      });
  },
});

export default userShowCartSlice.reducer;