import React from 'react'
import Article from '../Article/Article'
const FeaturedArticles = () => {
  return (
    <div style={{width:'100%', display:'flex', justifyContent:'center',alignItems:'center'}}>
  {/* With wrapping enabled */}
       <Article wrap={true} />
    </div>
  )
}

export default FeaturedArticles
