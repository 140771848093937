// showArticlesByInstructorSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';

export const fetchInstructorArticlesById = createAsyncThunk(
  'instructorArticlesById/fetchInstructorArticlesById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${URL}/instructor/${id}/articale`);
      return response.data.data.article;  // Extract 'article' array directly
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg || 'Failed to fetch articles');
    }
  }
);

const instructorArticlesByIdSlice = createSlice({
  name: 'instructorArticlesById',
  initialState: {
    articles: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorArticlesById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchInstructorArticlesById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload;  // Assign the article array to state.articles
      })
      .addCase(fetchInstructorArticlesById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default instructorArticlesByIdSlice.reducer;
