import React, { useState, useEffect } from 'react';
import DeleteCorse from '../../assets/images/DeleteCourse.svg';
import updatecourse from '../../assets/images/editcourse.svg';
import './ArticleDashboard.css';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorArticles } from '../../store/features/instructorArticleIdSlice'; // Import the action

const ArticleDashboard = () => {
  // State to manage the archived status for each article
  const [archivedArticles, setArchivedArticles] = useState([]);
  const navigate = useNavigate();

  // Function to toggle archive status for a specific article
  const toggleArchive = (id) => {
    setArchivedArticles((prevArchived) =>
      prevArchived.includes(id)
        ? prevArchived.filter((articleId) => articleId !== id) // Unarchive
        : [...prevArchived, id] // Archive
    );
  };

  // Link this component with redux-toolkit
  const dispatch = useDispatch();
  const { articles, status, error } = useSelector((state) => state.instructorArticle);

  // Fetch articles when the component is mounted
  useEffect(() => {
    dispatch(fetchInstructorArticles());
  }, [dispatch]);

  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'failed') {
    return <p>Error: {error}</p>;
  }

  const truncate = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const handleUpdateClick = (id) => {
    navigate(`/update-article/${id}`);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
      {articles.map((article) => (
        <div
          key={article.id}
          className={`${archivedArticles.includes(article.id) ? 'Unarchived_Article' : 'Article_container_Dashboard'}`}
        >
          <div className='Article_Sec1'>
            <img className='Article_Sec1' src={article.image} alt='ArticleImg' />
          </div>
          <div className='Article_Sec2'>5-10 mins to read</div>
          <div className='Article_Sec3' onClick={() => handleArticleClick(article.id)}>
            {truncate(article.title, 30)}
          </div>
          <div className='Article_Sec4' onClick={() => handleArticleClick(article.id)}>
            {truncate(article.description, 50)} readmore
          </div>
          <div className='Article_Sec5'>
            <div className='Author_information'>
              <div className='Article_Sec5_Part1'>
                <img className='author_icon' src={article.instructor.profile_image} alt='AuthorImage' />
              </div>
              <div className='Article_Sec5_Part2'>
                <div className='Article_Sec5_Part2_name' onClick={() => handleArticleClick(article.id)}>
                  {article.instructor.name}
                </div>
                <div className='Article_Sec5_Part2_date' onClick={() => handleArticleClick(article.id)}>
                  {article.publish_date}
                </div>
              </div>
            </div>
          </div>
          <div className='Course_Sec7'>
            <button
              className='Course_Dashboard_Archive'
              onClick={() => toggleArchive(article.id)}
            >
              {archivedArticles.includes(article.id) ? 'UnArchive' : 'Archive'}
            </button>
            <div className='course_icon_modify'>
              <img src={updatecourse} alt='updateCourse' style={{cursor:'pointer'}} onClick={() => handleUpdateClick(article.id)} />
              <img src={DeleteCorse} alt='deleteCourse' />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArticleDashboard;
