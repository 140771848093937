// استيراد الدوال والمكتبات اللازمة
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // بافتراض أن URL يتم استيراده من ملف إعدادات

// دالة fetchCourses تستدعي بيانات الدورات من خلال Async Thunk
export const fetchCourses = createAsyncThunk(
    'courses/fetchCourses', // اسم الـ thunk لتمييزه
    async (_, { rejectWithValue }) => { // تستقبل rejectWithValue لتمرير الأخطاء بشكل مناسب
      try {
        // إرسال طلب GET إلى endpoint /courses
        const response = await axios.post(`${URL}/courses`);
        console.log('API Course Response:', response.data); // طباعة استجابة الـ API في الكونسول
        return response.data.data.courses; // Extract the 'courses' array directly
        // التحقق من البيانات وإرجاع قائمة الدورات إذا كانت موجودة
     //   return response.data.data.courses || []; // تعديل حسب هيكل البيانات
      } catch (error) {
        console.error('API Error:', error); // طباعة الخطأ في الكونسول
        // إرجاع رسالة خطأ مخصصة إذا فشل جلب البيانات
        return rejectWithValue(error.response?.data?.msg || 'Failed to fetch courses');
      }
    }
);

// إنشاء الشريحة الخاصة بالدورات coursesSlice
const coursesSlice = createSlice({
    name: 'courses', // اسم الشريحة للتعريف
    initialState: {  // الحالة الابتدائية للشريحة
      list: [],        // مصفوفة فارغة لتخزين قائمة الدورات
      status: 'idle',  // حالة التحميل الابتدائية
      error: null      // حالة الخطأ الابتدائية
    },
    reducers: {},     // لا توجد دوال مخصصة إضافية هنا
    extraReducers: (builder) => { // إضافة حالات إضافية عند استدعاء الـ thunk
      builder
        .addCase(fetchCourses.pending, (state) => { // عند بدء جلب البيانات
          state.status = 'loading'; // تعيين حالة التحميل إلى loading
          state.error = null;       // إعادة تعيين الخطأ إلى null
        })
        .addCase(fetchCourses.fulfilled, (state, action) => { // عند نجاح جلب البيانات
          state.status = 'succeeded'; // تعيين الحالة إلى succeeded
          state.list = Array.isArray(action.payload) ? action.payload : []; // تخزين الدورات في القائمة، مع التأكد أنها مصفوفة
        })
        .addCase(fetchCourses.rejected, (state, action) => { // عند فشل جلب البيانات
          state.status = 'failed'; // تعيين الحالة إلى failed
          state.error = action.payload; // تخزين رسالة الخطأ
        });
    }
});

// تصدير المقتطف لاستخدامه في تكوين المتجر
export default coursesSlice.reducer;
