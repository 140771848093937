import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchArticleById } from '../../../../store/features/getArticleByIdSlice';
import { updateArticleById } from '../../../../store/features/instructorUpdateArticleSlice';
import LineDashboard from '../../../../assets/images/instructors/LineDashboard.svg';
import UploadIcon from '../../../../assets/images/lmg_upload.svg';
import { useNavigate } from 'react-router-dom';

const UpdateArticle = () => {
  const { id: articleId } = useParams();  // Extract articleId from the URL
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { article, status, error } = useSelector(state => state.getArticleById || {});  // Safe destructuring
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState('');  // To hold the URL of the existing image

  // Fetch the article by ID when the component loads
  useEffect(() => {
    if (articleId) {
      dispatch(fetchArticleById(articleId));  // Dispatch action to fetch the article
    }
  }, [dispatch, articleId]);

  // Update form fields when article data is fetched
  useEffect(() => {
    if (article) {
      setTitle(article.data.article.title);
      setDescription(article.data.article.description);
      setExistingImageUrl(article.data.article.image);  // Save the existing image URL
    }
  }, [article]);

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (selectedImage && typeof selectedImage !== 'string') {
      formData.append('image', selectedImage);
    }

    dispatch(updateArticleById({ id: articleId, formData })).then((result) => {
      if (status === 'succeeded') {
        // Reload the page after successful update
        navigate('/InstructorHome');
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Adjust the delay as needed
      }
    });
  };
  return (
    <div>
      <div className='Create_Article_Container'>
        <div className='InstructorCreateCourse_Sec1'>
          <div>Update Article</div>
        </div>

        <form onSubmit={handleSubmit} className='Create_Article_Form_Add'>
          {/* Article Title */}
          <div className='InstructorCreateCourse_form_Section'>
            <div className='InstructorCreateCourse_form_Section_title'> title of your article*</div>
            <input
              type='text'
              className='InstructorCreateCourse_form_Section_input'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          {/* Article Description */}
          <div className='InstructorCreateCourse_form_Section'>
            <div className='InstructorCreateCourse_form_Section_title'> Description for your Course*</div>
            <textarea
              rows="8"
              cols="50"
              className='InstructorCreateCourse_form_Section_input'
              style={{ height: '150px' }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <img src={LineDashboard} alt='LineDashboard' />

          {/* Cover Photo Upload */}
          <div className='upload-Container'>
            {selectedImage ? (
              <img src={URL.createObjectURL(selectedImage)} alt="Selected" className='image-preview' />
            ) : existingImageUrl ? (
              <img src={existingImageUrl} alt="Article" className='image-preview' />  // Show existing image from API
            ) : (
              <div className='placeholder'>
                <img src={UploadIcon} alt='uploadimage' />
                <p className='text'>Upload Photo</p>
                <small className='subtext'>Upload From Your Device Any Photo</small>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className='file-input'
              onChange={handleImageChange}
            />
          </div>

          {/* Update Button */}
          <div className='Publish_Article'>
            <button type='submit' className='InstructorCreateCourse_Sec1_publish_Button'>
              Update
            </button>
          </div>
        </form>

        <div className='Article_added_Alert'>
        {status === 'loading' && <p>done..</p>}
        {status === 'succeeded' && <p>Article updated successfully!</p>}
        {status === 'failed' && <p>Error: {error}</p>}
        </div>
      </div>
    </div>
  );
};

export default UpdateArticle;
