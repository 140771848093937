import React, { useState } from 'react';
import axios from 'axios';
//import SignupImage from '../../assets/images/signup.png';
import instructorLoginImage from '../../assets/images/instructorLogin.svg'
import studentLoginImage from '../../assets/images/studentLogin.svg'
import Logo from '../../assets/images/lndexlogo_svg.svg';
import './LoginPage.css';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
// redux-toolkit for manage APi
import { useDispatch , useSelector } from 'react-redux';
import { loginUser } from '../../store/featureAuth';
import { LineUtil } from 'leaflet';
import Spinner from 'react-bootstrap/Spinner';
import InstructorLogin from './InstructorLogin';
const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, user } = useSelector((state) => state.auth);
    // State for role, email, and password
    const [role, setRole] = useState('Student');
    const [active, setActive] = useState('Student'); // default is 'instructor'
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setError] = useState('');
    //const [credentials, setCredentials] = useState({ email: '', password: '' });
    // function Login
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }))
          .unwrap()
          .then(() => {
            navigate('/a');
          })
          .catch((err) => {
            console.error(err);
          });
      };

      const handleToggle = (role) => {
        setActive(role); // change the active role
        setRole(role)
        console.log(role)
      };
       
    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    
     /*
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/login', {
                email,
                password
            });

            // Handle successful login
             console.log('Login successful:', response.data);
              // Save token in a cookie
             // const  token  = response.data.user.token;
             if (response.status === 200 || 201) {
                const Token = response.data.data.user.token ; 
                console.log("Test Token",Token)
                Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
                navigate('/');
             }
            else if (response.status === 422 || 401){
                  setError("Wrong Email Or Password");
            } 

        } catch (err) {
            console.error('Login failed:', err);
            setError('Login failed. Please check your credentials and try again.');
        }
    };
      */
    return (
        <div className='Login_page_container' style={{marginTop:'25px'}}> 
            <div></div>
            <div className='Signup'>
                {/* Start signup body */}
                <div className='Signup_body'>
                    <div className='Login_page_hidden'>
                    {role === 'Instructor' ?   <img src={instructorLoginImage} alt='SignupImage' /> :   <img src={studentLoginImage} alt='SignupImage' /> }
                      
 
                    </div>
                    <div className='Signup_body_Form'>
                        <div className='Signup_body_Form_Sec1' style={{display:'flex',flexDirection:'row-reverse'}}>
                            <img src={Logo} alt='Logo' />
                            <div className="Instructor_Student_Container_login">
                                 <h6
                                   className={active === 'Instructor' ? 'active' : ''}
                                   onClick={() => handleToggle('Instructor')}
                                 >
                                 Instructor
                                 </h6>
                                  |
                                 <h6
                                   className={active === 'Student' ? 'active' : ''}
                                   onClick={() => handleToggle('Student')}
                                 >
                                 Student
                                 </h6>
                       </div>
                        </div>
                        <div className='Signup_body_Form_Sec2'>
                            Log In
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        {/* Start input Field  as Student */}

                        {role === 'Student' && (
                         <>
                          <div className='Signup_body_input_container'>
                            {/* Email */}
                              <div className='Signup_body_Form_Sec4'>
                                <div>Email</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='ayamustafa@gmail.com'
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>
                            </div>
                            {/* Password */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Password</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="password"
                                        placeholder='Password'
                                        value={password}
                                        onChange={handlePassword}
                                    />
                                </div>
                            </div>
                        </div>
                           {/* End input Field Container */}
                           {/**********  Error Login Student Section *****************/}
                        <div className='Error_message'> {error && (
                               <div>
                               <p></p>
                               <p>{error.msg}</p>
                               {/* Optionally render more details if needed */}
                               {error.data && <pre>{JSON.stringify(error.data.data, null, 2)}</pre>}
                            </div>
                              )}
                         </div>  
                           {/* Button to submit or if  End user ForgetPassWord  */}
                           <div className='Signup_body_Form_Sec5'>
                              {loading ?  <Spinner animation="border" variant="primary" /> : <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
                              Login
                              </button>}        

                              <div style={{display:'flex',flexDirection:"column",alignItems:'flex-end',gap:'10px'}}>
                              <Link to='/forget-password'>  <div style={{cursor:'pointer'}}>? Forget Password </div> </Link>
                              <div>Don’t have an account?<span> Sign Up</span></div>
                           </div>   
                         </div>        
                         </>   
                        )}
                        {/* Start Login Instructor field  */}
                         {role === 'Instructor' && (
                            <InstructorLogin />
                         )}   
                                          
                        <div className='Signup_body_Form_Sec3'>
                        
                            <hr />
                        </div>
                        <div className='Signup_body_Form_Sec6_footer'>
                            by clicking on sign up, you agree to Lndex <span>Termes and conditions </span> & <span>Privacy policy</span> and Agree to receive news and promotions
                        </div>
                    </div> {/* End Form */}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
