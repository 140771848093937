// store/features/GetInstructorCoursesById.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Ensure the URL is correctly exported from your config

// Async thunk to fetch courses for a specific instructor by ID
export const fetchInstructorCoursesById = createAsyncThunk(
  'instructorCoursesById/fetchInstructorCoursesById',
  async (instructorId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${URL}/courses/instructor/${instructorId}`);
      console.log('API Response for Instructor Courses:', response.data);

      // Ensure that the API response contains the courses array
      return response.data.data.courses || [];
    } catch (error) {
      console.error('API Error:', error);
      return rejectWithValue(error.response?.data?.msg || 'Failed to fetch instructor courses');
    }
  }
);

// Create the slice
const instructorCoursesByIdSlice = createSlice({
  name: 'instructorCoursesById',
  initialState: {
    list: [],
    status: 'idle',
    error: null,
  },
  reducers: {}, // No additional reducers needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorCoursesById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchInstructorCoursesById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(fetchInstructorCoursesById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Export the reducer for store configuration
export default instructorCoursesByIdSlice.reducer;
