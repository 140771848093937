import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';

const CategoryDropdown = ({ selectedCategory, onCategoryChange }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${URL}/categories`);
        console.log('API Response:', response.data);

        // Map the categories to only include active items for the dropdown
        const activeCategories = response.data.data.categories
          .filter(category => category.active === 1 && category.soft_delete === 0)
          .map(category => ({
            id: category.id,
            title: category.title,
          }));

        setCategories(activeCategories);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="category-dropdown">
      <select
        value={selectedCategory}
        onChange={(e) => onCategoryChange(e.target.value)}
        className="category-select"
        style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
      >
        <option value="">Select Category</option>
        {categories.map(category => (
          <option key={category.id} value={category.id}>
            {category.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CategoryDropdown;
