import { URL } from '../../config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

// Async thunk to handle the API call for adding a course to the cart
export const addCourseToCart = createAsyncThunk(
  'cart/addCourseToCart',
  async (courseId, { rejectWithValue }) => {
    try {
      const authToken = Cookies.get('authToken');
      const config = {
        headers: {
          token: authToken,
        },
      };
      const response = await axios.post(
        `${URL}/user-courses-atc`,
        { course_id: courseId },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the Redux slice for managing the cart state
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    adding: false,
    error: null,
    success: false,
    courseData: null, // To hold course data if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCourseToCart.pending, (state) => {
        state.adding = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addCourseToCart.fulfilled, (state, action) => {
        state.adding = false;
        state.success = true;
        state.error = null;
        state.courseData = action.payload.data.course;
      })
      .addCase(addCourseToCart.rejected, (state, action) => {
        state.adding = false;
        state.success = false;
        state.error = action.payload || 'Error adding course to cart';
      });
  },
});

export default cartSlice.reducer; // Export only the reducer here
