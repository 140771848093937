import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSavedCourses } from '../../../store/userFeature/userFetchSavedCourseSlice'; // Correct import
import Loading from '../../../component/Loading/Loading';
import Clock from '../../../assets/images/Course/circle-clock1.png'
import Chair from '../../../assets/images/Course/Chair.png'
import Line from '../../../assets/images/Course/Line.png'
import Delete from '../../../assets/images/Course/cart_Delete.svg'
import Start from '../../../assets/images/Course/Star.png'
import AddToCart from '../../../assets/images/coursecard/shopping-cart-details.svg'
import SavedCompact from '../../../assets/images/Course/SavedCompact.svg'
import  './userSaved.css'
const UserSavedCourse = () => {
  const dispatch = useDispatch();

  // Get saved courses and loading/error states from Redux store
  const { courses, loading, error } = useSelector((state) => state.userFetchSavedCourse || {}); // Corrected to userFetchSavedCourse

  useEffect(() => {
    // Dispatch the action to fetch saved courses when the component mounts
    dispatch(fetchUserSavedCourses());
  }, [dispatch]);

  // Display loading spinner while fetching
  if (loading) return <Loading />;

  // Display error message if there's an error
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Your Saved Courses</h2>
      {courses && courses.length === 0 ? (
        <p>No saved courses.</p> // Display message if no courses are saved
      ) : (
        <div className="course-cards-container">
          {/* Map over courses and display them as course cards */}
          {courses && courses.map((course) => (
            <div key={course.id}    className='Course_Container'>
               <div className='Course_Sec1'>
                   <img className='Course_Sec1' src={course?.image} alt='CourseImg' />
                </div>
              <div className="course-detail">
              <div className='Course_Sec3'>
                   {course?.title}
              </div>
              <div className='Course_Sec4'>
                 <img src={Clock} alt='Clock'/> {course?.course_hours} &nbsp; ({course.session_count} sessions)
              </div>
              <div className='Course_Sec5'>
                   <img src={Chair} alt='Chair'/>{course?.seats_count} seats left
                   <img src={Line} alt='Line'></img>
                   <img src={Start} alt='Start'></img>{course?.rate ? course?.rate : '95%'}(234)   {/* ternary operation beacause if isnt rate exict give me 95%  */}
              </div>
              <div style={{paddingTop:'8px',color:'blue'}} className='Course_Sec6'>
                   {course?.category?.title}
               </div>
               <div className='Course_Sec6'>
                   {course?.instructor?.name}
               </div>
                <p className="Course_Sec6"> {parseFloat(course?.price).toFixed(2)} EGP</p>

                <div className='Course_Saved_in_Component'>
                <div><img src={Delete} alt="DeleteDelete" className='Course_Saved_in_Component_icons' /></div> 
                    <img src={SavedCompact} alt="SavedCompact" className='Course_Saved_in_Component_icons'/>
                    <img src={AddToCart} alt="addtocart_icon" className='Course_Saved_in_Component_icons'/>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserSavedCourse;
