// src/redux/slices/followInstructorSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // استيراد الـ URL من ملف config
import Cookies from 'js-cookie';

// async thunk لمتابعة المدرب
export const followInstructor = createAsyncThunk(
  'user/followInstructor', // اسم الـ action
  async (instructorId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // استرجاع الـ token من الـ cookies
      // إرسال طلب POST لمتابعة المدرب
      const response = await axios.post(
        `${URL}/user-follow-instructor`,
        { instructor_id: instructorId },
        {
          headers: {
            'Content-Type': 'application/json',
            token: token, // إرسال الـ token في الهيدر
          },
        }
      );
      return response.data; // إرجاع البيانات في حالة النجاح
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to follow instructor'); // في حالة الخطأ، إرجاع الرسالة
    }
  }
);

// async thunk لإلغاء متابعة المدرب
export const unfollowInstructor = createAsyncThunk(
  'user/unfollowInstructor', // اسم الـ action
  async (instructorId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('authToken'); // استرجاع الـ token من الـ cookies
      // إرسال طلب POST لإلغاء متابعة المدرب
      const response = await axios.post(
        `${URL}/user-unfollow-instructor`,
        { instructor_id: instructorId },
        {
          headers: {
            'Content-Type': 'application/json',
            token: token, // إرسال الـ token في الهيدر
          },
        }
      );
      return response.data; // إرجاع البيانات في حالة النجاح
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to unfollow instructor'); // في حالة الخطأ، إرجاع الرسالة
    }
  }
);

// إنشاء Slice
const followInstructorSlice = createSlice({
  name: 'followInstructor',
  initialState: {
    following: {}, // التأكد من أن followStatus يبدأ ككائن فارغ
    loading: false,
    error: null,
  },
  reducers: {
    setFollowStatus: (state, action) => {
      // وضع حالة المتابعة لكل مدرب بناءً على رد الـ API
      state.following = { ...state.following, [action.payload.id]: action.payload.following };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(followInstructor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(followInstructor.fulfilled, (state, action) => {
        state.loading = false;
        state.following[action.payload.id] = true; // تحديث المتابعة
      })
      .addCase(followInstructor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(unfollowInstructor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unfollowInstructor.fulfilled, (state, action) => {
        state.loading = false;
        state.following[action.payload.id] = false; // تحديث إلغاء المتابعة
      })
      .addCase(unfollowInstructor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFollowStatus } = followInstructorSlice.actions;
export default followInstructorSlice.reducer; // تصدير الـ reducer لاستخدامه في الـ store
