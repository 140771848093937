import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loading from './component/Loading/Loading'; // Import the loading component

// Import all your pages
import Blog from './pages/Blog/index';
import Home from './pages/Home/index';
//import IndexInstructor from './pages/InstructorPage/index';
import CartPage from './pages/Cart/index';
import SearchPage from './pages/SearchPage/index';
import CourseDetails from './pages/CourseDetails/index';
import ArticleById from './pages/ArticleById/index';
import LoginPage from './pages/LoginPage/index';
import SignupPage from './pages/SignupPage/index';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import UserProfile from './pages/UserProfile/index';
import UserHome from './pages/UserHome/index';
import UpdateProfile from './pages/UpdateProfile/index';
import Forgetpassword from './pages/ForgetPassword/index';
import VerfiyCode from './pages/VerfiyCode/index';
import ChangePassword from './pages/ChangePassword/index';
import Bootstrap from './component/Bootstrap/Bootstrap';
import UserCart from './pages/userPages/UserCart/index';
import UserBlog from './pages/userPages/UserBlog/index';
import IndexUserInstructor from './pages/userPages/UserShowInstructor/index';
import Testform from './pages/Testform/TestForm';
import SavedCourses from './pages/SavedCourses/index';
import InstructorHome from './pages/InstructorAsUser/InstrectorHome/index';
import InstructorCreateCourse from './pages/InstructorAsUser/InstructorDashboard/index';
import CreateArticle from './pages/InstructorAsUser/InstructorDashboard/InstructorcreateArticle/index';
import ArticleDetails from './component/ArticleDetailsById/Article_Details';
import UpdateArticle from './pages/InstructorAsUser/InstructorDashboard/instructorUpdate/index'
import EditInformation from './pages/InstructorAsUser/InstrectorHome/EditInformation/index';
import UpdateCourse from './pages/InstructorAsUser/InstructorDashboard/instructorUpdateCourse/index';
import UserSavedCourse from './pages/userPages/UserSavedCourse/index';

function MainApp() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show loader when route is changing
    setIsLoading(true);

    // Hide loader after a short delay to simulate content loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
       
      {isLoading && <Loading />} {/* Show loading when isLoading is true */}
      <Routes>
         
        <Route path="/" element={<Home />} />
        <Route path="/a" element={<UserHome />} />
        <Route path="/Blogs" element={<Blog />} />
        <Route path="/instructor/:id" element={<IndexUserInstructor  />} /> {/* New route for instructor details */}
       {/* <Route path="/instructor/:id" element={<IndexUserInstructor  />} /> {/* New route for instructor details */}
        <Route path="/Cart" element={<CartPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/coursedetails/:id" element={<CourseDetails />} />
        <Route path="/Article/1" element={<ArticleById />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Signup" element={<SignupPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/:slug" element={<UserProfile />} />
        <Route path="/update-profile" element={<UpdateProfile />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/verfiy-code" element={<VerfiyCode />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/Bootstrap" element={<Bootstrap />} />
        <Route path="/UserCart" element={<UserCart />} />
        <Route path="/UserBlog" element={<UserBlog />} />
        <Route path="/User-saved-course" element={<UserSavedCourse />} />
        <Route path="/Testform" element={<Testform />} />
        <Route path="/savedcourse" element={<SavedCourses />} />
        <Route path="/article/:id" element={<ArticleDetails />} /> {/* Route to article details page */}
        <Route path="/InstructorHome" element={<InstructorHome />} />
        <Route path="/createcourse" element={<InstructorCreateCourse />} />
        <Route path="/create-Article" element={<CreateArticle />} />
        <Route path="/update-article/:id" element={<UpdateArticle />} />
        <Route path="/update-course/:id" element={<UpdateCourse />} />
        <Route path="/Edit-information" element={<EditInformation />} />
        <Route path="/*" element={'Not Found This Page'} />
      </Routes>
    </>
  );
}

export default MainApp;
