import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice';
import instructorLoginReducer from './features/instructorLoginSlice';
import instructorProfileReducer from './features/instructorGetProfileSlice';
import instructorArticleIdReducer from './features/instructorArticleIdSlice';
import getArticleByIdReducer from './features/getArticleByIdSlice';
import updateArticleByIdReducer from './features/instructorUpdateArticleSlice';
import updateProfileImageReducer from './features/instructorProfilePictureSlice';
import instructorStoreCourseReducer from './features/instructorStoreCourseSlice';
import updateInstructorProfileReducer from './features/InstructorUpdateProfileSlice';
import instructorsReducer from './features/Getallinstructor'; // Import the new slice
import instructorCourseReducer  from './features/instructorCoursesSlice';
import instructorUpdateCourseReducer from './features/instructorUpdateCourseSlice';
import GetCourseReducer from './features/GetCourses';
import instructorCoursesByIdReducer  from './userFeature/showCoursesByInstructorIdSlice'
import instructorArticlesByIdReducer from './userFeature/showArticlesByInstructorSlise'
// here the user reducer 
import userAddToCartReducer from './userFeature/addCourseToCartSlice'
import userShowCartReducer from './userFeature/showUserCartSlice'; // استيراد الـ slice
import userFollowInstructorReducer from './userFeature/userFollowInstructorSlice' // import user follow instructor Slice 
import userSavedCourseReducer from './userFeature/userSavedCourseSlice' 
import userFetchSavedCourseReducer from './userFeature/userFetchSavedCourseSlice'


export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    instructorLogin: instructorLoginReducer,
    instructorProfile: instructorProfileReducer,   
    instructorArticle: instructorArticleIdReducer,
    getArticleById: getArticleByIdReducer,
    updateArticleById: updateArticleByIdReducer,
    updateProfileImage: updateProfileImageReducer,
    instructorStoreCourse: instructorStoreCourseReducer,
    updateInstructorProfile: updateInstructorProfileReducer,
    instructors: instructorsReducer ,// Add the instructors slice here
    instructorCourses  : instructorCourseReducer , 
    instructorUpdateCourse :  instructorUpdateCourseReducer,
    GetCourse : GetCourseReducer ,
    instructorCoursesById : instructorCoursesByIdReducer , 
    instructorArticleById : instructorArticlesByIdReducer ,
     // this about user reducer add to the store 
    userAddToCart :  userAddToCartReducer ,
    userShowCart: userShowCartReducer,
    userFollowInstructor : userFollowInstructorReducer , 
    userSavedCourse : userSavedCourseReducer ,
    userFetchSavedCourse: userFetchSavedCourseReducer ,
    
  },
});

export default store;
