import React from 'react'
import './InstructorNav.css'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg' 
import NotificationIcon from '../../assets/images/Notfication.svg'
import WorldLanguage from '../../assets/images/worldLanguage.svg'
// Redux-Toolkit  =>>>>> 
import { useDispatch , useSelector } from 'react-redux'
import { instructorLogout } from '../../store/features/instructorLoginSlice'
import { useNavigate } from 'react-router-dom';
const InstructorNav = () => {

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.instructorLogin); // الوصول لحالة تسجيل الدخول
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(instructorLogout()); // تشغيل تسجيل الخروج عند النقر على الزر
    navigate('/login')
  };



  return (
    <div className='InstructorNav_Container'>
          {/* The LeftSide Logo  */}
         <div className='InstructorNav_Logo'>
             <img src={LndexLogo} alt='logo' />
         </div>
          {/* The Right Side  */}
          <div className='InstructorNav_RightSide'>
                 <div> <img src={NotificationIcon} alt='notficationIcon'/></div>
                 <div><button className='InstructorNav_LanguageButton'><img src={WorldLanguage} alt='langIcon'/>العربيه</button></div>
                 <div><button onClick={handleLogout} disabled={loading} className='InstructorNav_LogoutButton'>  {loading ? <div className="spinner-border spinner-border-sm text-light" role="status">
                <span className="sr-only" style={{color:'#000'}}></span>
              </div>
               : 'Logout'}
               </button>
               </div>
          </div>
           {error && <p>Error: {error}</p>}
    </div>
  )
}
export default InstructorNav