import React from 'react'
import Article from '../Article/Article'
const NewArticle = () => {
  return (
    <div className='Blog_Sec5'>
    {/* With wrapping enabled */}
       <Article wrap={true} />
    </div>
  )
}

export default NewArticle
