import React, { useState } from 'react';
import './Form.css'; // Import your CSS file here

const ProfileForm = () => {
  // State to manage which form is selected
  const [selectedForm, setSelectedForm] = useState('changeEmail');

  // Function to handle form switch
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };

  return (
    <div className="form-container">
      {/* Toggle Buttons */}
      <div className="toggle-buttons">
        <label>
          <input
            type="radio"
            value="changeEmail"
            checked={selectedForm === 'changeEmail'}
            onChange={() => handleFormChange('changeEmail')}
          />
          Change your Gmail
        </label>
        <label>
          <input
            type="radio"
            value="changePassword"
            checked={selectedForm === 'changePassword'}
            onChange={() => handleFormChange('changePassword')}
          />
          Change your Password
        </label>
      </div>

      {/* Render the form based on selected option */}
      {selectedForm === 'changeEmail' ? (
        <div className="form-content">
          <h3>Change your Gmail</h3>
          <input type="text" placeholder="User name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Current password" />
          <button>Save changes</button>
        </div>
      ) : (
        <div className="form-content">
          <h3>Change your Password</h3>
          <input type="password" placeholder="Current password" />
          <input type="password" placeholder="New password" />
          <input type="password" placeholder="Repeat new password" />
          <a href="/forgot-password">Forgot your password?</a>
          <button>Save changes</button>
        </div>
      )}
    </div>
  );
};

export default ProfileForm;
