// src/features/auth/authThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { URL } from '../config';
// Login thunk

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/login`, credentials);
      console.log(response.data)
      const Token = response.data.data.user.token ; 
      console.log("Test Token",Token)
      Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
      const fName = response.data.data.user.first_name;
      const lName = response.data.data.user.last_name;
      const email = response.data.data.user.email;
      const userData = {
        first_name: fName,
        last_name: lName,
        email: email
    };
    localStorage.setItem("user_data", JSON.stringify(userData));
    return response.data; // this will be the payload in the fulfilled state
      
    } catch (err) {
      return rejectWithValue(err.response.data);
      //console.log(err.response.data)
    }
  }
);
// Register thunk
export const registerUser = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/register', userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// change ForgetPassword 

export const forgetPassword = createAsyncThunk(
  'auth/forgetPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/forget-password`, { email });
      return response.data;
    } catch (error) {
      return rejectWithValue('This account does not exist.');
    }
  }
);

// now this about thunk =>>>>>>>>>>>>>>>>>>>> Changepassword from the user profile with sent a token old password and newpassword 

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ old_password, new_password }, { rejectWithValue }) => {
    try {
      // token 
      const token = Cookies.get('authToken'); // test token 
      const response = await axios.post(
        `${URL}/update-password`,
        { old_password, new_password }, // request body =>> sent from changepassword component 
        {
          headers: {
            token: `${token}`, // send token in Authorization header ===> token store at cookies 
          }
        }
      );
       console.log(response)
      return response.data; // Assuming the API returns some success message or data
    } catch (error) {
      // If the request fails, return the error message
      return rejectWithValue(error.response.data);
    }
  }
);