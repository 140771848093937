import { createSlice } from '@reduxjs/toolkit';
import { loginUser ,registerUser ,changePassword} from './featureAuth';

const initialState = {
  user: null,
  token: null,
  loading: false,
  error: null,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        console.log(state.user)
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle registration
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
        // Handle change password
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
        .addCase(changePassword.fulfilled, (state, action) => {
          state.loading = false;
          // You might want to handle the state changes here if needed
          console.log('Password changed successfully', action.payload);
        })
        .addCase(changePassword.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
  },
});

export default AuthSlice.reducer;