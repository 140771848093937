import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';

// Thunk to update article by ID
export const updateArticleById = createAsyncThunk(
  'article/updateArticleById',
  async ({ id, formData }, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token');
    try {
      const response = await axios.post(`${URL}/update-article/${id}`, formData, {
        headers: {
          token: token,
          'Content-Type': 'multipart/form-data', // Important for handling file uploads
        },
      });
      console.log('Update Article Response:', response.data); // Log the successful response
      return response.data;
    } catch (error) {
      console.error('Update Article Error:', error.response ? error.response.data : error.message); // Log the error
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice with fetchArticleById and updateArticle actions
const updateArticleSlice = createSlice({
  name: 'article',
  initialState: {
    article: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Update article by ID
      .addCase(updateArticleById.pending, (state) => {
        state.status = 'loading';
        console.log('Update Article: Loading...'); // Log loading status
      })
      .addCase(updateArticleById.fulfilled, (state, action) => { // Ensure 'action' is defined here
        console.log('Update Article: Succeeded with data:', action.payload); // Log the data on success
        state.status = 'succeeded';
        // Optionally update the article in the state if needed
        // state.article = action.payload; // Uncomment this line if you want to store the updated article
      })
      .addCase(updateArticleById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error('Update Article: Failed with error:', action.payload); // Log the error on failure
      });
  },
});

export default updateArticleSlice.reducer;
