import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '../../../assets/images/instructors/add.svg';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import ArticleDashboard from '../../../component/ArticleDashboard/ArticleDashboard';


const InstructorArticles = () => {
  return (
    <div className="container-fluid">
      <div className='row mb-3'>
        <div className="col-lg-12 d-flex justify-content-end">
          <Link to='/create-Article' className='link'>
            <button className='InstructorHome_AddCourse_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-2' />
              Create New Article
            </button>
          </Link>
        </div>
      </div>

      <div className='Courses_Instructor_Container'>
        {/* Iterate through the courses array to render CourseDashboard components dynamically */}
       
          <div>
            <ArticleDashboard />
          </div>
      
      </div>
    </div>
  );
};

export default InstructorArticles;
