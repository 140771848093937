import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../store/featureAuth';
import Spinner from 'react-bootstrap/Spinner';
const ChangeYourPassword = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth); // Access loading and error states from Redux

  // Local state for form inputs
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [validationError, setValidationError] = useState(''); // Local state for validation errors

  // Function to handle the password change
  const handleChangePassword = () => {
    // Validate new password and repeated password match
    if (newPassword !== repeatNewPassword) {
      setValidationError('New passwords do not match');
   
      return;
    }

    // Clear validation errors
    setValidationError('');

    // Dispatch the Redux thunk to change the password
    dispatch(changePassword({ old_password: currentPassword, new_password: newPassword }));
  };

  return (
    <div className='ChangeYourPassword_Container'>
      {/* Current Password Field */}
      <div className='Chang_Pass_Sec'>
        <div className='Chang_Pass_Sec_label'>Current password</div>
        <input
          type='password'
          className='Sign_input'
          style={{ width: '100%' }}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>

      {/* New Password Field */}
      <div className='Chang_Pass_Sec'>
        <div className='Chang_Pass_Sec_label'>New password</div>
        <input
          type='password'
          className='Sign_input'
          style={{ width: '100%' }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>

      {/* Repeat New Password Field */}
      <div className='Chang_Pass_Sec'>
        <div className='Chang_Pass_Sec_label'>Repeat New password</div>
        <input
          type='password'
          className='Sign_input'
          style={{ width: '100%' }}
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
        />
      </div>

      {/* Button Section */}
      <div className='Chang_Pass_Buttons'>
        <div className='Chang_Pass_Buttons_Forget'>Forgot your password</div>
        <div>
         
          {loading ?   <Spinner animation="border" variant="primary" />  : <button className='Chang_Pass_Buttons_Save' onClick={handleChangePassword} disabled={loading}> Save changes </button>} {/* Show loading text */}
         
        </div>
      </div>

      {/* Display Validation or API Error */}
      {validationError && <p className='error-message'>{validationError}</p>}
      {error && <p className='error-message'>{error}</p>}
    </div>
  );
};

export default ChangeYourPassword;
