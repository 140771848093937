import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';
export const fetchInstructorArticles = createAsyncThunk(
  'instructorArticles/fetchArticles',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token');
    try {
      const response = await axios.get(`${URL}/instructor-articles`, {
        headers: {
            token: token, // Add token to the Authorization header
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const instructorArticlesSlice = createSlice({
  name: 'instructorArticles',
  initialState: {
    articles: [],
    status: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInstructorArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload.data.article;
      })
      .addCase(fetchInstructorArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.msg;
      });
  },
});

export default instructorArticlesSlice.reducer;
