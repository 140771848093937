import React, { useEffect , useState} from 'react';
import './Course.css'
//import CourseImg from '../../assets/images/Course/Courseimage.png'
import Clock from '../../assets/images/Course/circle-clock1.png'
import Chair from '../../assets/images/Course/Chair.png'
import Line from '../../assets/images/Course/Line.png'
import Start from '../../assets/images/Course/Star.png'
import SavedCompact from '../../assets/images/Course/SavedCompact.svg'
import Saved from '../../assets/images/Course/CourseSaved.png'
import AddToCart from '../../assets/images/coursecard/shopping-cart-details.svg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../store/features/GetCourses'
import Loading from '../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { addCourseToCart } from '../../store/userFeature/addCourseToCartSlice';
import { saveCourse } from '../../store/userFeature/userSavedCourseSlice'; // Import saveCourse action
import { Link } from 'react-router-dom'; // Import Link
import Cookies from 'js-cookie';
//import Popup from '../popUp/popup';

const Course = () => {
  const token = Cookies.get('authToken'); // Retrieve the token from cookies
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [savedCourses, setSavedCourses] = useState({}); // Track saved courses by ID
 // استخدام useSelector للوصول إلى حالة الدورات من المخزن
 const { list: courses, status, error } = useSelector((state) => state.GetCourse);
 const { success, adding, courseData } = useSelector((state) => state.userAddToCart); // Access the add to cart 
 const { saved, loading: saving } = useSelector((state) => state.userSavedCourse);

 // استدعاء fetchCourses عند تحميل المكون لأول مرة
 useEffect(() => {
   dispatch(fetchCourses()); // طلب جلب الدورات عند تحميل المكون
 }, [dispatch]);


  // Local state to manage the message display



 //console.log('Test Course : ' , courses)

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

   // handle for navigating to course details 
   const handleCourseClick = (id) => {
       navigate(`/coursedetails/${id}`)
   }

   const handleAddToCartClick = (courseId) => {
    const token = Cookies.get('authToken');
    if (!token) {
      setPopupMessage('Please log in to save courses to your cart.');
      setShowPopup(true);
      return;
    }
    dispatch(addCourseToCart(courseId));
  };

  const handleSaveCourseClick = (courseId) => {
    const token = Cookies.get('authToken');
    if (!token) {
      setPopupMessage('Please log in to save courses.');
      setShowPopup(true);
      return;
    }
    dispatch(saveCourse(courseId)).then((action) => {
      if (action.type.endsWith('/fulfilled')) {
        setSavedCourses((prev) => ({ ...prev, [courseId]: true })); // Mark course as saved
      }
    });
  };

// Show the popup message when the add to cart action completes
// Popup display logic in Course component
useEffect(() => {
  if (success) {
    setPopupMessage('Course added to cart successfully!');
    setShowPopup(true);
  } else if (error) {
    setPopupMessage('Failed to add course to cart.');
    setShowPopup(true);
  }

  // Automatically close the popup after 3 seconds
  const timer = setTimeout(() => {
    setShowPopup(false);
  }, 3000);

  return () => clearTimeout(timer); // Clean up the timer on component unmount
}, [success, error]); // Depend on success and error

 // عرض حالة التحميل أو الرسائل في حال الفشل
 if (status === 'loading') return <p>Loading courses...</p>;
 if (status === 'failed') return <p>Error: {error}</p>;
  return (

<>
    {status === 'loading' ?  <Loading/> : 
    
    <>

      {courses.map((course)=> (    
    <div 
       className='Course_Container'
       key={course.id}
       onClick={() => handleCourseClick(course.id)}
    >

      
    <div>
        <div className='Course_Sec1'>
           <img className='Course_Sec1' src={course.image} alt='CourseImg' />
        </div>
    
         {/*  Sec2 Course Body  */}
   <div className='Course_Sec2'>
        Start 11 October
   </div>
   <div className='Course_Sec3'>
      {course.title}
   </div>
   <div className='Course_Sec4'>
   <img src={Clock} alt='Clock'/> {course.course_hours} &nbsp; ({course.session_count} sessions)
</div>
  <div className='Course_Sec5'>
    <img src={Chair} alt='Chair'/>{course.seats_count} seats left
    <img src={Line} alt='Line'></img>
    <img src={Start} alt='Start'></img>{course.rate ? course.rate : '95%'}(234)   {/* ternary operation beacause if isnt rate exict give me 95%  */}
 </div>

 <div style={{paddingTop:'8px',color:'blue'}} className='Course_Sec6'>
      {course.category.title}
   </div>

 <div className='Course_Sec6'>
      {course.instructor.name}
 </div>

 <div className='Course_Sec7_Home_guest'>
   
 <div onClick={(e) => {
                    e.stopPropagation();
                    handleSaveCourseClick(course.id);
                  }}>
                   <img src={savedCourses[course.id] ? SavedCompact : Saved} alt="Save" />
                  </div>

    <div onClick={(e) => {
                    e.stopPropagation(); // Prevent the parent click from firing
                    handleAddToCartClick(course.id); // Trigger add to cart
                  }}>
                    <img src={AddToCart} alt="addtocart_icon" />
      </div>
 </div>
    
    
    </div>

</div>
  ))}

</>
        
    }
   
  {/* Conditionally render the Popup */}
      {/* Popup for login/signup message */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
          <div> <span className="close" onClick={closePopup}>&times;</span></div> 
            <div><p className='Popup_Message'>{popupMessage}</p></div> 

            {!token ?
              <div className='popup_button_container'> 
              {/* Render Login and Signup buttons only if the user is not authenticated */}
           
                    <Link className="link" to="/Login">
                      <div>
                        <button className="popup_button_Login">Login</button>
                      </div>
                    </Link>
                    <Link className="link" to="/Signup">
                      <div>
                        <button className="popup_button_Signup">Sign Up</button>
                      </div>
                    </Link>
                  
              </div>  
             : 
            ''
          
          }
           
          </div>
        </div>
      )}
    </>
  )
}
export default Course

