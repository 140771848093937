// InstructorCreateCourse.jsx
import React, { useState, useRef, useCallback, useEffect } from 'react';
import './instructorcreateCourse.css';
import LineDashboard from '../../../assets/images/instructors/LineDashboard.svg';
import VolumeIcon from '../../../assets/images/Course/VolumeLevel.svg';
import UploadIcon from '../../../assets/images/lmg_upload.svg';
import ExitIcon from '../../../assets/images/instructors/exitIcon.svg';
import { useDispatch , useSelector } from 'react-redux';
import { storeCourse } from '../../../store/features/instructorStoreCourseSlice';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../../config';
import CourseLevelSelector from './CourseLevelSelector';
import CategoryDropdown from './Category';
import CityDropdown from './City';
import TimeSessionSelector from './TimeSessionSelector'


const libraries = ["places"]; // لاستخدام المكتبة العادية بدون advanced markers
const InstructorCreateCourse = () => {
  const dispatch = useDispatch();
  //console.log(typeof storeCourse); // Should log 'function'
  const { loading, error } = useSelector((state) => state.instructorStoreCourse);
  // select level Id
  const [selectedLevel, setSelectedLevel] = useState('');
  const handleLevelChange = (levelId) => {
    setSelectedLevel(levelId);
    console.log('Selected Level ID:', levelId); // Optional: Log the selected level ID
  };
   // select category by id 
   const [selectedCategory, setSelectedCategory] = useState('');

   const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  // Select City By Id 
  const [selectedCity, setSelectedCity] = useState('');

   const handleCityChange = (cityId) => {
     setSelectedCity(cityId);
    };

  // Time | Duration
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [sessions, setSessions] = useState(3);


  // map and define lat and lng 
  const [markerPosition, setMarkerPosition] = useState({ lat: 30.033333, lng: 31.233334 });
  const mapRef = useRef(null);
  console.log("Test Map : ",markerPosition.lng)
  const onLoad = useCallback((map) => {
    mapRef.current = map;


  // إنشاء Marker عادي قابل للسحب
  const marker = new window.google.maps.Marker({
    position: markerPosition,
    map: mapRef.current,
    draggable: true,
  });

    // إضافة Listener لتحديث الإحداثيات بعد السحب
    marker.addListener('dragend', (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      console.log("New Position:", newPosition );
    });
  }, [markerPosition]);


  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
 

  const [formValues, setFormValues] = useState({
    category_id: selectedCategory,
    level_id: selectedLevel,
    city_id: selectedCity,
    title: '',
    type : '',
    promoVideoLink: '',
    description: '',
    content: '',
    has_certicate: '',
    courseLanguage: '',
    price: '',
    session_count: sessions, // initialize with sessions
    seats_count: '',
    course_hours: hours, // initialize with hours
    numSets: '',
    certified: null,
    lat: markerPosition.lat,
    lng: markerPosition.lng,
    faqs: [{ question: 'test', answer: 'test' }],
  });

  
   console.log('formValues.type',formValues.type ,formValues , )

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setImagePreviewUrl(URL.createObjectURL(file)); // Create a URL for the selected file
  };

  // Sync formValues.course_hours with hours from TimeSessionSelector
  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      course_hours: hours,
    }));
  }, [hours]);

  // Sync formValues.session_count with sessions from TimeSessionSelector
  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      session_count: sessions,
    }));
  }, [sessions]);
 
    // Synchronize category_id with selectedCategory
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        category_id: selectedCategory,
      }));
    }, [selectedCategory]);
  
    // Synchronize city_id with selectedCity
    useEffect(() => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        city_id: selectedCity,
        level_id :selectedLevel
      }));
    }, [selectedCity , selectedLevel]);


    // Update formValues.lat and formValues.lng whenever markerPosition changes
useEffect(() => {
  setFormValues((prevFormValues) => ({
    ...prevFormValues,
    lat: markerPosition.lat,
    lng: markerPosition.lng,
  }));
}, [markerPosition]);

  const handleImageClick = () => fileInputRef.current.click();


  const handleSubmit = async (e) => {
    e.preventDefault();

    const courseData = {
      ...formValues,
      image: selectedFile,
    };

    const resultAction = await dispatch(storeCourse(courseData));

    if (storeCourse.rejected.match(resultAction)) {
      console.error("Error storing course:", resultAction.payload);
    } else {
      console.log("Course stored successfully:", resultAction.payload);
    }
  };

 

  return (
    <div> 
    <form onSubmit={handleSubmit} className="InstructorCreateCourse_Container">

          {/* Course Title */}
    <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Title*</div>
        <input
          type="text"
          name="title"
          value={formValues.title}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>

 {/*****************  Course Title  *******************/}
      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Description*</div>
        <textarea
          rows="5"
          name="description"
          value={formValues.description}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>
         

    <div style={{display:'flex', width:'50%'}}>  
      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Category </div>
        <CategoryDropdown
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
      </div>


        <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Level </div>
          <CourseLevelSelector selectedLevel={selectedLevel} onLevelChange={handleLevelChange} />
        </div> 

     </div> 


     <div style={{display:'flex', width:'50%'}}>  
       <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Type Of Course</div>
        <select
           name="type"
           value={formValues.type}
           onChange={handleInputChange}
           className="city-select"
           style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
        >
          <option value="1">Offline</option>
          <option value="0">Online</option>
        </select>
      </div>


      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">City </div>
         <CityDropdown
           selectedCity={selectedCity}
           onCityChange={handleCityChange}
           language="en" // or 'ar' based on preference
         />
      </div>

   </div>

      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Promo Video Link*</div>
        <textarea
          rows="5"
          name="promoVideoLink"
          value={formValues.promoVideoLink}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>

   

      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Content*</div>
        <textarea
          rows="5"
          name="content"
          value={formValues.content}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>

                     <div className='InstructorCreateCourse_form_Section'>
                                <div className='InstructorCreateCourse_form_Section_title'>Duration*</div> 
                                <TimeSessionSelector 
                                 hours={hours}
                                 setHours={setHours}
                                 minutes={minutes}
                                 setMinutes={setMinutes}
                                 sessions={sessions}
                                 setSessions={setSessions}
                                
                                />

                        </div>

      <div className="InstructorCreateCourse_form_Section">
  <div className="InstructorCreateCourse_form_Section_title">Has Certificate*</div>
  <select
    name="has_certicate"
    value={formValues.has_certicate}
    onChange={handleInputChange}
    className="InstructorCreateCourse_form_Section_input"
  >

    <option value="1">Yes</option>
    <option value="0">No</option>
  </select>
</div>


      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Price*</div>
        <input
          type="number"
          name="price"
          value={formValues.price}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>
    {/* 
    
     <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Session Count*</div>
        <input
          type="number"
          name="session_count"
          value={formValues.session_count}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>
    
    
    
    */}
     

      <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Seats Count*</div>
        <input
          type="number"
          name="seats_count"
          value={formValues.seats_count}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>

 {/* 
 
  <div className="InstructorCreateCourse_form_Section">
        <div className="InstructorCreateCourse_form_Section_title">Course Hours*</div>
        <input
          type="number"
          name="course_hours"
          value={formValues.course_hours}
          onChange={handleInputChange}
          className="InstructorCreateCourse_form_Section_input"
        />
      </div>
 
 */}
     

  

      <div className="InstructorCreateCourse_form_Section">
          <div className="InstructorCreateCourse_form_Section_title">Select Location*</div>
          <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
          <GoogleMap
           mapContainerStyle={{ height: "400px", width: "100%" }}
           center={markerPosition}
           zoom={10}
           onLoad={onLoad}
          />
          </LoadScript>
        </div>

     <div className="InstructorCreateCourse_form_Section">
      <div className="InstructorCreateCourse_form_Section_title">Upload Cover Photo*</div>
      <div onClick={handleImageClick} style={{ cursor: 'pointer', position: 'relative', height: '300px', width: '100%', overflow: 'hidden' }}>
        <img src={UploadIcon} alt="Upload Icon" style={{ width: '50px', height: '50px', position: 'absolute', top: '10px', left: '10px' }} />
        {selectedFile && <p style={{ position: 'absolute', top: '70px', left: '10px', color: '#000' }}>{selectedFile.name}</p>}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        {imagePreviewUrl && ( // Show the selected image if available
          <img
            src={imagePreviewUrl}
            alt="Selected Cover"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Cover the entire container
          />
        )}
      </div>
    </div>

      <button type="submit" className="InstructorCreateCourse_Sec1_publish_Button">
        Submit Course
      </button>
         {/* Show loading spinner or message */}
         {loading && <p>Loading... Please wait.</p>}

         {/* Display error message */}
         {error && <p style={{ color: 'red' }}>Error: {error.response || 'Failed to store course'}</p>}
    </form>
    </div>
  );
};

export default InstructorCreateCourse;
