import React from 'react';
import { useNavigate } from 'react-router-dom';
import CourseImg from '../../assets/images/Course/Courseimage.png';
import Clock from '../../assets/images/Course/circle-clock1.png';
import Chair from '../../assets/images/Course/Chair.png';
import Line from '../../assets/images/Course/Line.png';
import Start from '../../assets/images/Course/Star.png';
import './courseDashboard.css';
import DeleteCourse from '../../assets/images/DeleteCourse.svg';
import UpdateCourseIcon from '../../assets/images/editcourse.svg';

const CourseDashboard = ({ course }) => {
  // استخدام هوك useNavigate للتنقل بين الصفحات
  const navigate = useNavigate();

  // دالة لمعالجة النقر على زر التحديث والتنقل إلى صفحة التحديث مع تمرير بيانات الدورة
  const handleUpdateClick = () => {
    navigate(`/update-course/${course.id}`, { state: { course } });
  };

  return (
    <div className='Course_Container'>
      {/* عرض صورة الدورة */}
      <div className='Course_Sec1'>
        <img style={{ width: '348px', height: '220px' }} src={course.image || CourseImg} alt='Course' />
      </div>
      {/* عرض تاريخ بدء الدورة */}
      <div className='Course_Sec2'>
        Start {course.start_date || 'N/A'}
      </div>
      {/* عرض عنوان الدورة */}
      <div className='Course_Sec3_at_dashboard'>
        {course.title}
      </div>
      {/* عرض عدد الساعات وعدد الجلسات */}
      <div className='Course_Sec4'>
        <img src={Clock} alt='Clock' /> {course.course_hours} hours &nbsp; ({course.session_count} sessions)
      </div>
      {/* عرض عدد المقاعد المتبقية وتقييم الدورة */}
      <div className='Course_Sec5'>
        <img src={Chair} alt='Chair' /> {course.seats_count} seats left
        <img src={Line} alt='Line' />
        <img src={Start} alt='Start' /> {course.rate || 'N/A'} ({course.session_count || 0})
      </div>
      {/* عرض وصف الدورة */}
      <div className='Course_Sec6'>
        {course.description}
      </div>
      {/* عرض أزرار الأرشفة وتعديل وحذف الدورة */}
      <div className='Course_Sec7'>
        <button className='Course_Dashboard_Archive'>
          Archive
        </button>
        <div className='course_icon_modify'>
          <img src={UpdateCourseIcon} alt='Update Course' onClick={handleUpdateClick} />
          <img src={DeleteCourse} alt='Delete Course' />
        </div>
      </div>
    </div>
  );
};

export default CourseDashboard;
