import React , { useState } from 'react'
import { useEffect } from 'react'
import './instructorHome.css'
import EditInstructorIcon from '../../../assets/images/instructors/Icon-Edit.svg'
import Line from '../../../assets/images/Line.svg'
import InstructorCourses from './InstructorCourses'
import InstructorArticles from './instructorArticles'
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorProfile } from '../../../store/features/instructorGetProfileSlice'
import { updateProfileImage } from '../../../store/features/instructorProfilePictureSlice';
import Loading from '../../../component/Loading/Loading'
import AboutInstructor from './AboutInstructor'
import SaveIcon from '../../../assets/images/instructors/save.svg'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
const InstructorHome = () => {
     const navigate = useNavigate();
     // Data From Redux

     useEffect(() => {
      // جلب التوكن من الكوكيز
      const token = Cookies.get('Instructor_Token');
  
      // التحقق إذا كان التوكن غير موجود
      if (!token) {
        // إعادة التوجيه إلى صفحة تسجيل الدخول
        navigate('/login');
      }
    }, [navigate]); // التأكد من تشغيل هذا الـ useEffect مرة واحدة عند التحميل
     const dispatch = useDispatch();
     const { profile, loading, error } = useSelector((state) => state.instructorProfile);
   //  const { status: imageStatus } = useSelector((state) => state.updateProfileImage);
     const [selectedImage, setSelectedImage] = useState(null);
     const [fileToUpload, setFileToUpload] = useState(null); // Store the selected file
    // console.log("profile: ",profile)
   // To active Section 4 Item 
   const [activeSection , setActiveSection]= useState('Courses')
   const renderSection = () => {
    switch (activeSection) {
      case 'Review':
        return '<InstructorReview />';
      case 'Courses':
        return   <InstructorCourses /> ;
      
      case 'Articles':
        return <InstructorArticles/>;
      case 'About':
        return <AboutInstructor profile={profile} />;
      default:
        return null;
    }
  };
    // navigate to Edit Profile Instructor
  const handleEditInfoClick = () => {
    navigate('/Edit-information');
  };

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFileToUpload(file); // Store the file for later uploading
    }
  };

// Handle the save action (upload the image)
const handleSaveImage = () => {
  if (fileToUpload) {
    const formData = new FormData();
    formData.append('profile_image', fileToUpload);

    dispatch(updateProfileImage(formData))
      .then(() => {
        // Optionally dispatch fetch profile action or reload page
        dispatch(fetchInstructorProfile()); // Ensure you have this action imported and available
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating image:', error);
      });
  }
};

   useEffect(() => {
     dispatch(fetchInstructorProfile());
   }, [dispatch]);
   
   if (loading) return <Loading />;
   if (error) return <p>Error: {error}</p>;
   if (!profile) return <p>No profile data available</p>;

  return (
      
    <div className='InstructorHome_Container'>
              {/* all page divided as Sections*/}  
              {/* Section One "Welcome Instructor" */}  
         <div className='InstructorHome_Section1'> 
            Welocme Back {profile.instructor.last_name}!
            <div className='InstructorPage_Sec1_Part1'></div>
         </div>
        
           {/* Section Two select from instructor Profile  "Welcome Instructor" */}  

          <div className='InstructorHome_Sec2_Container'>
           <div style={{display:'flex',alignItems:'center',gap:'32px'}}>
           <div className='InstructorPage_Sec2_Part1'>
            {/* Clickable profile image for uploading new image */}
            <label htmlFor='upload-profile-image' style={{ cursor: 'pointer' }}>
              <img
                className='profile_image_instructor'
                src={selectedImage || profile.instructor.profile_image}
                alt='InstructorImage'
              />
            </label>
            <input
              type='file'
              id='upload-profile-image'
              style={{ display: 'none' }}
              onChange={handleImageChange}
              accept='image/*'
            />
          </div>



                      <div className='InstructorPage_Sec2_part2'>
                             <div>
                                 <div className='InstructorPage_Sec2_part2_name'>{profile.instructor.first_name} {profile.instructor.last_name}</div>
                                 <div className='InstructorPage_Sec2_part2_Major'>{profile.instructor.job_title}</div>
                                 <div className='InstructorPage_Sec2_part2_JoinDate'>Joined June {profile.instructor.created_at}</div>
                             </div>
                             <button className='InstructorPage_Sec2_part2_EditInfo'    onClick={handleEditInfoClick}><img src={EditInstructorIcon} alt='editIcon'/> Edit My Info</button>
                                  {/* Display Save button after an image is selected */}
                                  {fileToUpload && (
                                     <button className='InstructorPage_SaveButton' onClick={handleSaveImage}>
                                      <img src={SaveIcon} alt='saveicon'/> Save New Image
                                     </button>
                                      )}
                     </div>
                     </div>
                                 {/* Section 2 Right Side  */}
                              <div>dd</div>   
                                
                     </div> 
                       {/* Section 3 and 4  */}
                        {/*  Start Section 4 */}
                      <div className='InstructorHomePage_Sec4'>
                      <div className={activeSection === 'Courses' ? 'active' : '' }
                      onClick={() => setActiveSection('Courses')}
                      >Courses</div>
                      <div className={activeSection === 'Articles'? 'active' : '' }
                      onClick={() => setActiveSection('Articles')}
                      >Articles</div>
                      <div className={activeSection === 'About' ? 'active' : '' }
                      onClick={() => setActiveSection('About')}
                      >About</div>
                      <div className={activeSection === 'Review'? 'active' : '' }
                      onClick={() => setActiveSection('Review')}
                      >Review</div>
             </div>
             <div className='Line_instractor_Dashboard'><img src={Line} alt='Line'/></div>
     
             <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'50px'}}>  
               {renderSection()}
             </div>
  
    </div>
  )
}
export default InstructorHome