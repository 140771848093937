import React , { useState } from 'react'
import './InstructorPage.css'
import Line from '../../assets/images/Line.svg'
import InstructorImage from '../../assets/images/instructors/instructor1.png'
//import Course from '../../component/Course/Course'
//import Article from '../../component/Article/Article'
import FeaturedArticles from '../../component/FeaturedArticles/FeaturedArticles'
import CourseIns from '../../component/InstructorCourse/InstructorCourse'
import InstructorAbout from '../../component/InstructorAbout/InstructorAbout'
import InstructorReview from '../../component/InstructorReview/InstructorReview'
import { useLocation } from 'react-router-dom';
import InstructorArticle from '../../component/instructorArticle/instructorArticle'
const Instructorpage = () => {
   // To active Section 4 Item 


   const location = useLocation();
   const instructor = location.state?.instructor;
   console.log('uselocation to extract data : ',instructor)


   const [activeSection , setActiveSection]= useState('Courses')
   const renderSection = () => {
    switch (activeSection) {
      case 'Review':
        return <InstructorReview />;
      case 'Courses':
        return   <CourseIns instructorId={instructor.id}/> ;
      
      case 'Articles':
        return <InstructorArticle instructorId={instructor.id}  wrap={true}/>;
      case 'About':
        return <InstructorAbout  instructorAbout={instructor?.about_details}/>;
      default:
        return null;
    }
  };

 
  if (!instructor) {
    return <p>No instructor data found.</p>;
  }
  return (
    <div>
         {/* Start Instrector Page Container  */}
         <div className='InstructorPage_Continer'>
             {/* Start Instrector Page Container  */}
             <div className='InstructorPage_Header'>
              <span style={{color:'Highlight'}}>instructor</span> profile
             </div>
           {/* Start Design Section One  */}
           <div className='InstructorPage_Sec1'>
              <div className='InstructorPage_Sec1_Part1'></div>
               {/* Part 2  */}
                      {/* Part 2 = >  contain Section 2 and Section 3 inside section 1 
                       and have style space between around it 
                      */}
              <div className='InstructorPage_Sec1_Part2'>
                      {/* The Information Instructor Section   */}
                      {/*  Instructor Card Section 2    */}
                 <div className='InstructorPage_Sec2'>
                     <div className='InstructorPage_Sec2_Part1'>
                        <img className='InstructorImage' src={instructor?.profile_image} alt='InstructorImage'></img>
                      </div>
                     <div className='InstructorPage_Sec2_part2'>
                             <div>
                                 <div className='InstructorPage_Sec2_part2_name'>{instructor?.name}</div>
                                 <div className='InstructorPage_Sec2_part2_Major'>{instructor?.career ? instructor?.career :'Lndex Instructor'}</div>
                                 <div className='InstructorPage_Sec2_part2_JoinDate'>Joined June 2021</div>
                             </div>
                             <button className='InstructorPage_Sec2_part2_Button'>Follow</button>
                     </div>
                  </div>
                      {/*  END Instructor Section 2  (Card) */}
                      {/* Section three Instructor Relation Blog and Course in Profile  */}
                   <div className='InstructorPage_Sec3'>
                             <div className='InstructorPage_Sec3_Part1'>{instructor?.email ? instructor?.email :'instractorwebsite.co'} </div>
                             <div className='InstructorPage_Sec3_Part2'>
                                 <div className='InstructorPage_Sec3_Part2_item'><div>6</div><span>Courses</span></div>
                                 <div className='InstructorPage_Sec3_Part2_item'><div>4</div><span>Articles</span></div>
                                 <div className='InstructorPage_Sec3_Part2_item'><div>582</div><span>students</span></div>
                             </div>
                   </div>
                      {/*  END Section 3 */}
               
              </div>
                      {/*  Start Section 4 */}
                      <div className='InstructorPage_Sec4'>
                      <div className={activeSection === 'Courses' ? 'active' : '' }
                      onClick={() => setActiveSection('Courses')}
                      >Courses</div>
                      <div className={activeSection === 'Articles'? 'active' : '' }
                      onClick={() => setActiveSection('Articles')}
                      >Articles</div>
                      <div className={activeSection === 'About' ? 'active' : '' }
                      onClick={() => setActiveSection('About')}
                      >About</div>
                      <div className={activeSection === 'Review'? 'active' : '' }
                      onClick={() => setActiveSection('Review')}
                      >Review</div>
             </div>
             <div className='Line'><img src={Line} alt='Line'/></div>
             <div className='InstructorPage_Sec5'>  
               {renderSection()}
             </div>
           </div>
           
         </div>
    </div>
  )
}

export default Instructorpage
