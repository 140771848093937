import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URL } from '../../../config';

const CityDropdown = ({ selectedCity, onCityChange, language = 'en' }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${URL}/cities`);
        console.log('API Response:', response.data);

        // Format the cities to display either `name_en` or `name_ar`
        const formattedCities = response.data.data.cities.map(city => ({
          id: city.id,
          name: language === 'ar' ? city.name_ar : city.name_en,
        }));

        setCities(formattedCities);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCities();
  }, [language]); // Refetch data if the language changes

  const handleCityChange = (e) => {
    onCityChange(e.target.value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="city-dropdown">
      <select
        value={selectedCity}
        onChange={handleCityChange}
        className="city-select"
        style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
      >
      
        {cities.map(city => (
          <option key={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CityDropdown;
