
import React , { useState , useEffect } from 'react'

import Footer from '../../../component/Footer/Footer';
import FooterAR from '../../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../../context/LanguageContext';
import InstructorCreateCourse from './InstructorCreateCourse';
import MobileNavbar from '../../../component/mobileNavbar/MobileNavbar';
import InstructorNav from '../../../component/InstructorNav/InstructorNav';
const IndexInstructorDashboard = () => {

     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
     toggleLanguage()
   //   const isLogggedin = true
// const [language , toggleLanguage ]= useLanguage() ; .
// IsMobile and Responsive
const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };
  // Initial check
  handleResize();
  // Event listener for window resize
  window.addEventListener('resize', handleResize);
  // Clean-up
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div>
  
       {!isMobile && (<>
        {language === 'en' && isMobile === false ?  <InstructorNav />
          : 
          <></>
     } 
       </>)
       }
   {isMobile && (
       <MobileNavbar /> 
   )}
  
 
      {language === 'en' ? <InstructorCreateCourse />  : <InstructorCreateCourse />} 
      {language === 'en' ?  <Footer/> : <FooterAR /> } 
    </div>

  )
}

export default IndexInstructorDashboard