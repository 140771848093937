import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorProfile } from '../../../../store/features/instructorGetProfileSlice';
import { updateInstructorProfile } from '../../../../store/features/InstructorUpdateProfileSlice';
import './EditIstructor.css';

const EditInformation = () => {
  const dispatch = useDispatch();

  const instructorProfile = useSelector(state => state.instructorProfile.profile);
  const loading = useSelector(state => state.instructorProfile.loading);
  const error = useSelector(state => state.instructorProfile.error);
  const updateStatus = useSelector(state => state.instructorProfile.updateStatus);

  const [submitError, setSubmitError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    about_details: '',
    job_title: '',
    career :'' , 
  });

  useEffect(() => {
    dispatch(fetchInstructorProfile());
  }, [dispatch]);

  useEffect(() => {
    if (instructorProfile) {
      setFormData({
        first_name: instructorProfile.instructor.first_name,
        last_name: instructorProfile.instructor.last_name,
        email: instructorProfile.instructor.email,
        phone: instructorProfile.instructor.phone,
        about_details: instructorProfile.instructor.about_details,
        job_title: instructorProfile.instructor.job_title,
        career :  instructorProfile.instructor.career
      });
    }
  }, [instructorProfile]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);
    try {
      const response = await dispatch(updateInstructorProfile(formData)).unwrap();
      console.log('Profile update response:', response); // Log the response to ensure success
      setShowSuccessMessage(true)
    } catch (error) {
      setSubmitError(error);
    }
  };

  return (
    <div className='Edit_Instructor_Container'>
      <h2>Edit Profile Information</h2>

      {loading && <p>Loading profile...</p>}
      {error && <p>Failed to load profile: {error}</p>}

      {instructorProfile && (
        <form className="edit-profile-form" onSubmit={handleSubmit}>
          <label>First Name:</label>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />
          
          <label>Last Name:</label>
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label>About:</label>
          <textarea
            name="about_details"
            value={formData.about_details}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
            style={{ height: '100px' }}
          ></textarea>

          <label>Job Title:</label>
          <input
            type="text"
            name="job_title"
            value={formData.job_title}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />

          <label> Career:</label>
          <input
            type="text"
            name="career"
            value={formData.career}
            onChange={handleChange}
            className="InstructorEdit_form_Section_input"
          />


          <div className='InstructorEdit_form_submit_button_container'> 
            <button 
              type="submit" 
              className="InstructorEdit_form_submit_button" 
              disabled={updateStatus === 'loading'}
            >
              {updateStatus === 'loading' ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Updating...
                </>
              ) : (
                'Update Profile'
              )}
            </button>
          </div>

          {/* Display success or error message */}
          <div className="message-container">
            {showSuccessMessage && <p style={{color:'green' , fontWeight:'700',fontSize:'15px'}} className="success-message">Profile updated successfully!</p>}
            {submitError && <p className="error-message">Failed to update profile: {submitError}</p>}
          </div>
        </form>
      )}
    </div>
  );
};

export default EditInformation;
