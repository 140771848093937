import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './component/ScrollToTop/ScrollToTop';
import MainApp from './MainApp'; // Import the main component
import './App.css'
function App() {
  return (
    <Router>
        <ScrollToTop />
      <MainApp /> {/* Load MainApp inside the router */}
    </Router>
  );
}

export default App;
