import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';

// Thunk to update instructor profile image
export const updateProfileImage = createAsyncThunk(
  'instructor/updateProfileImage',
  async (formData, { rejectWithValue }) => {
    const token = Cookies.get('Instructor_Token');
    try {
      const response = await axios.post(`${URL}/instructor/update-profile-image`, formData, {
        headers: {
          token : token, // Use Authorization header with Bearer token
          'Content-Type': 'multipart/form-data', // Important for handling file uploads
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error in updateProfileImage:', error.response.data); // Log error response
      return rejectWithValue(error.response.data); // Return error message
    }
  }
);

// Slice to manage profile image update state
const updateProfileImageSlice = createSlice({
  name: 'updateProfileImage',
  initialState: { status: null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateProfileImage.fulfilled, (state, action) => {
      state.status = 'success';
    });
    builder.addCase(updateProfileImage.rejected, (state, action) => {
      state.status = 'failed';
    });
  },
});

export default updateProfileImageSlice.reducer;