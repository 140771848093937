import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config';
import Cookies from 'js-cookie';
// Async thunk لإرسال طلب تسجيل الدخول للمدرس
export const instructorLogin = createAsyncThunk(
  'instructor/login', 
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${URL}/instructor/login`, { email, password });
      console.log(response.data); // للتحقق من البيانات المستلمة
      console.log(response.data.data.instructor.token); // للتحقق من وجود الـ token
      return response.data.data.instructor.token; // إعادة التوكن عند النجاح
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : 'Login failed'); // إرسال الخطأ في حالة الفشل
    }
  }
);

// Async thunk لإرسال طلب تسجيل الخروج للمدرس (GET request with token)
export const instructorLogout = createAsyncThunk(
  'instructor/logout',
  async (_, { rejectWithValue }) => {
    try {
      // Get the token from cookies
      const token = Cookies.get('Instructor_Token');
      
      // إرسال طلب GET لتسجيل الخروج مع التوكن
      const response = await axios.get(`${URL}/instructor/logout`, {
        headers: {
          token : token // تمرير التوكن في الهيدر
        }
      });
      
      console.log(response.data); // التحقق من بيانات الاستجابة
      return response.data; // إعادة الاستجابة في حالة النجاح
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : 'Logout failed'); // إرسال الخطأ في حالة الفشل
    }
  }
);


// Slice لحالة تسجيل الدخول
const instructorLoginSlice = createSlice({
  name: 'instructorLogin',
  initialState: {
    token: null,  // توكن تسجيل الدخول
    loading: false, // حالة التحميل
    error: null, // الأخطاء
  },
  /*
  reducers: {
    logout: (state) => {
      state.token = null;
      Cookies.remove('Instructor_Token'); // إزالة التوكن من الكوكيز عند تسجيل الخروج
    },
  },*/
  extraReducers: (builder) => {
    builder
      .addCase(instructorLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(instructorLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload; // حفظ التوكن في الحالة
        Cookies.set('Instructor_Token', state.token, { expires: 7 }); // Expires in 7 days
      })
      .addCase(instructorLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // حفظ الخطأ في الحالة
      })
                // logout Cases
                // في حاله تسجيل الخروج للانستريكتور  
      .addCase(instructorLogout.pending, (state)=>{
         state.loading = true ; 
      })
      .addCase(instructorLogout.fulfilled, (state) => {
        state.loading = false;
        state.token = null;

        Cookies.remove('Instructor_Token'); // إزالة التوكن من الكوكيز
        window.location.reload(); // إعادة تحميل الصفحة بعد تسجيل الخروج
      })
      .addCase(instructorLogout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; //           حفظ الخطأ في حالة الفشل
      });
  },
});

//export const { logout } = instructorLoginSlice.actions;
export default instructorLoginSlice.reducer;
