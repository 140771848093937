import React , { useState } from 'react'

const Description = ({course}) => {
  console.log(course)
  const [Expaned , setExpaned ] = useState(false)   
  const ToggleExpaned = () => {
      setExpaned(!Expaned)
  }
  return (
    <div className='Description_Container'>
        <div className='Description_Sec1'>
           <div className='Description_Sec1_header'>Description</div>
           <div className='Description_Sec1_header_body'>
             This all description about this course if you want to read press readmore 
        
           {Expaned && (
             <div>
                {course.description}
             </div>
           )}
            <div onClick={ToggleExpaned} className='Description_Sec1_Expaned'>{Expaned ? 'read less' : 'read More'}</div>
           </div>
          
        </div>
         {/* Start To Design Section 2  */}
         <div className='Description_Sec2'>
               <div className='Description_Sec2_header'>Upon completion of this course, students will be able to:</div>
               <div className='Description_Sec2_List'>
                 <ul>
                   <li>Define marketing and explain its key concepts and principles.</li>
                   <li>Identify the different types of marketing and their respective goals.</li>
                   <li>Understand the marketing process, from market research to customer relationship management.</li>
                   <li>Analyze target markets and develop marketing strategies to reach them.</li>
                   <li>Measure and evaluate the effectiveness of marketing campaigns.</li>
                 </ul>
               </div>
         </div>
    </div>
  )
}

export default Description