import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
//import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import './UpdateCourse.css';
import { updateCourse } from '../../../../store/features/instructorUpdateCourseSlice';

//import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../../../config';
import CourseLevelSelector from '../CourseLevelSelector';
import CategoryDropdown from '../Category';
import CityDropdown from '../City';
import UpdateFormIcon from '../../../../assets/images/Course/update_form.png'
// to link this form with redux 
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


/*
const mapContainerStyle = {
  width: '80%',
  height: '450px',
  marginTop: '25px',
  padding :'0px'
};
*/

const UpdateCourse = () => {
  const { state } = useLocation();
  const course = state?.course;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: course?.title || '',
    courseHours: course?.course_hours || '',
    sessionCount: course?.session_count || '',
    seatsCount: course?.seats_count || '',
    content: course?.content || '',
    description: course?.description || '',
    has_certicate: course?.has_certicate || '0',
    category_id: course?.category_id || '1',
    level_id: course?.level_id || '2',
    type: course?.type || '1',
    city_id: course?.city_id || '',
    price : course?.price || '',
  });

  const [previewImage, setPreviewImage] = useState(course?.image || '');
  
  const [location, setLocation] = useState({
    lat: String(course?.lat) || '',
    lng: String(course?.lng) || ''
  });
    
  /*
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  */

  const handleCategoryChange = (categoryId) => {
    setFormData((prevData) => ({
      ...prevData,
      category_id: categoryId,
    }));
  };

  const handleLevelChange = (levelId) => {
    setFormData((prevData) => ({
      ...prevData,
      level_id: levelId,
    }));
  };

  const handleCityChange = (cityId) => {
    setFormData((prevData) => ({
      ...prevData,
      city_id: cityId,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };
   
  /*
  const handleMapClick = (e) => {
    const lat = String(e.latLng.lat());
    const lng = String(e.latLng.lng());
    setLocation({ lat, lng });
  };
  */

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('content', formData.content);
    formDataToSend.append('course_hours', formData.courseHours);
    formDataToSend.append('session_count', formData.sessionCount);
    formDataToSend.append('seats_count', formData.seatsCount);
    formDataToSend.append('has_certificate', formData.has_certicate);
    formDataToSend.append('price', formData.price);
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }
    formDataToSend.append('category_id', formData.category_id);
    formDataToSend.append('level_id', formData.level_id);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('city_id', formData.city_id);
    formDataToSend.append('lat', location.lat);
    formDataToSend.append('lng', location.lng);

    const courseId = course?.id;

    dispatch(updateCourse({ courseId, courseData: formDataToSend }))
      .unwrap()
      .then((response) => {
        console.log("Course updated successfully:", response);
        navigate("/InstructorHome");
      })
      .catch((err) => {
        console.error("Failed to update course:", err);
        if (err.response) {
          console.error("Error response:", err.response.data);
        }
      });
  };
  return (
    <div className="update-course-container">
      <div style={{display:'flex',alignItems:'center' , width:'100%', justifyContent:'space-between',paddingBottom:'5vw'}}> <img src={UpdateFormIcon} alt='iconUpdate' style={{width:'65px'}} />   <h3>Course {course?.title}</h3>  <div></div>  </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Course Title</label>
          <input
            type="text"
            name="title"
            className="update-course-input"
            value={formData.title}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label>Course Price</label>
          <input
            type="text"
            name="price"
            className="update-course-input"
            value={formData.price}
            onChange={handleInputChange}
          />
        </div>



        {/* 
        <div>
          <label>Start Date</label>
          <input
            className="update-course-input"
            type="date"
            name="startDate"
            value={formData.created_at}
            onChange={handleInputChange}
          />
        </div>
*/}

       {/* Start DropDown List  */}

       <div style={{ display: 'flex', gap:'5vw' , width:'60vw'}}>
  <div>
    <div className="InstructorCreateCourse_form_Section_title">Course Category</div>
    <CategoryDropdown
      selectedCategory={formData.category_id}
      onCategoryChange={handleCategoryChange}
    />
  </div>

  <div>
    <div className="InstructorCreateCourse_form_Section_title">Course Level</div>
    <CourseLevelSelector
      selectedLevel={formData.level_id}
      onLevelChange={handleLevelChange}
    />
  </div>
</div>


             {/* Another DropDown */}

             <div style={{ display: 'flex', gap:'5vw',width:'50vw' }}>  
  <div>
    <div className="InstructorCreateCourse_form_Section_title">Type Of Course</div>
    <select
      name="type"
      value={formData.type}
      onChange={(e) => setFormData({ ...formData, type: e.target.value })}
      className="city-select"
      style={{ width: '320px', height: '40px', padding: '8px', border: 'none', backgroundColor: '#EDEFFF', borderRadius: '8px' }}
    >
      <option value="1">Offline</option>
      <option value="0">Online</option>
    </select>
  </div>

  <div>
    <div className="InstructorCreateCourse_form_Section_title">City</div>
    <CityDropdown
      selectedCity={formData.city_id}
      onCityChange={handleCityChange}
      language="en" // or 'ar' for Arabic
    />
  </div>

</div>


        <div>
          <label>Course Hours</label>
          <input
            className="update-course-input"
            type="number"
            name="courseHours"
            value={formData.courseHours}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Session Count</label>
          <input
            className="update-course-input"
            type="number"
            name="sessionCount"
            value={formData.sessionCount}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Remaining Seats</label>
          <input
            className="update-course-input"
            type="number"
            name="seatsCount"
            value={formData.seatsCount}
            onChange={handleInputChange}
          />
        </div>

        <div className="InstructorCreateCourse_form_Section">
                 <label>Has Certificate*</label>
             <select
                 name="has_certicate"
                 value={formData.has_certicate}
                 onChange={handleInputChange}
                 className="InstructorCreateCourse_form_Section_input"
             >
                       <option value="1">Yes</option>
                       <option value="0">No</option>
                  </select>
            </div>


        <div>

      {/* 
          <label> Course Rate </label>
          <input
            className="update-course-input"
            type="text"
            name="rate"
            value={formData.rate}
            onChange={handleInputChange}
          />

          */}

            </div>
        <div>
          <label>Course Description</label>
          <textarea
            className="update-course-input"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Course Content</label>
          <textarea
            className="update-course-input"
            name="content"
            value={formData.content}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Course Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        {previewImage && (
          <div>
            <img src={previewImage} style={{width:'100%'}} alt="Course Preview" />
          </div>
        )}

        {/* Google Map for Course Location 
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
            zoom={12}
            onClick={handleMapClick}
          >
            <Marker position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }} />
          </GoogleMap>
        ) : (
          <p>Loading map...</p>
        )}
          */}
       <div style={{display:'flex', justifyContent:'flex-end', padding:'20px'}}>
         <button  type="submit" className="update-course-submit-button">Save Changes</button>
       </div>
       </form>
   
      
    </div>
  );
};

export default UpdateCourse;
