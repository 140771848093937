import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../../config';

// Async thunk to fetch instructor profile data
export const fetchInstructorProfile = createAsyncThunk(
  'instructor/profile', 
  async (_, { rejectWithValue }) => {
    try {
      // Get the token from cookies
      const token = Cookies.get('Instructor_Token');

      // Make a GET request to fetch the instructor's profile data
      const response = await axios.get(`${URL}/instructor/profile`, {
        headers: {
          token: token, // Add token to the Authorization header
        },
      });

      console.log(response.data); // Check the received data
      return response.data.data; // Return the profile data on success
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : 'Failed to fetch profile');
    }
  }
);

// Create a slice for instructor profile
const instructorGetProfileSlice = createSlice({
  name: 'instructorProfile',
  initialState: {
    profile: null,  // Store the instructor profile data
    loading: false, // Handle loading state
    error: null,    // Handle error state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInstructorProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload; // Store the fetched profile data
      })
      .addCase(fetchInstructorProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error if profile fetching fails
      });
  },
});

export default instructorGetProfileSlice.reducer;
