import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../../assets/images/instructors/add.svg';
import CourseDashboard from '../../../component/courseDashboard/courseDashboard';
import { fetchInstructorCourses } from '../../../store/features/instructorCoursesSlice';

const InstructorCourses = () => {
  const dispatch = useDispatch();
  
  // Ensure to access the correct state slice
  const instructorCourses = useSelector((state) => state.instructorCourses);
  const { courses = [], loading = false, error = null } = instructorCourses;

  useEffect(() => {
    dispatch(fetchInstructorCourses());
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className='row mb-3'>
        <div className="col-lg-12 d-flex justify-content-end">
          <Link to='/createcourse' className='link'>
            <button className='InstructorHome_AddCourse_Button'>
              <img src={AddIcon} alt='AddTheIcon' className='me-2' />
              Create New Course
            </button>
          </Link>
        </div>
      </div>

      <div className='Courses_Instructor_Container'>
        {loading && <div>Loading courses...</div>}
        {error && <div>Error: {error}</div>}
        {courses.length > 0 ? (
          courses.map((course) => (
            <div key={course.id}>
              <CourseDashboard course={course} />
            </div>
          ))
        ) : (
          <div>No courses available.</div>
        )}
      </div>
    </div>
  );
};

export default InstructorCourses;
