// Import necessary functions and libraries
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; // Assuming URL is exported from a config file

// Async thunk to fetch the list of instructors
// Async thunk to fetch the list of instructors
export const fetchInstructors = createAsyncThunk(
    'instructors/fetchInstructors',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${URL}/instructors`);
        console.log('API Response:', response.data); // Log the API response
        
        // Access instructors from the correct structure
        return response.data.data.instructors || []; // Adjust this line
      } catch (error) {
        console.error('API Error:', error); // Log the error
        return rejectWithValue(error.response?.data?.msg || 'Failed to fetch instructors');
      }
    }
  );
  

// Create the slice
const instructorsSlice = createSlice({
    name: 'instructors',
    initialState: {
      list: [],      // This is correct to initialize as an empty array
      status: 'idle', // Initial loading status
      error: null    // Initial error state
    },
  reducers: {}, // No additional reducers needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructors.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchInstructors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = Array.isArray(action.payload) ? action.payload : []; // Ensure payload is an array
      })
      .addCase(fetchInstructors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

// Export the reducer for store configuration
export default instructorsSlice.reducer;
