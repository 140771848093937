import React from 'react'
import './sidebar.css'
import  logo  from '../../assets/images/lndexlogo_svg.svg'
import Home from '../../assets/images/Subtract.png'
import Cart from '../../assets/images/shopping-cart.png'
import mycourse from '../../assets/images/Courses.png'
import saved from '../../assets/images/Saved.png'
import Blog from '../../assets/images/blogSvg.svg'
import careerpath from '../../assets/images/careerpath.png'
import { Link } from 'react-router-dom'
const Sidebar = () => {
  return (
    <div>
    <div className='Sidebar_container'>
      {/* this wrap logo and list with gap 100 between this  */}
         <div className='Sidebar_Sec1'>
           <div><img src={logo} alt='lndexlogo'></img></div>
           <div className='Sidebar_Part1_List'>
              <ul>
              <Link to='/a' className='link'>   <li><img src={Home} alt='home_icon'></img>home</li></Link>
              <Link to='/UserCart' className='link'>  <li style={{display:'flex',gap:'5vw'}}><div><img src={Cart} alt='cart_icon'></img> cart</div><span className='cart_item'>1</span></li></Link>
                <li><img src={mycourse} alt='mycourse'></img>my course</li>
               <Link to='/User-saved-course'  className='link'><li><img src={saved} alt='saved'></img>saved course</li></Link> 
               <Link to='/UserBlog' className='link'> <li><img src={Blog} alt='cart_icon'></img>Blog</li></Link>
                <li><img src={careerpath} alt='careerpath'></img>Career paths</li>
              </ul>
           </div>
         </div>
         <div></div>
         <div className='Sidebar_Footer'>@Copyright Lndex All rights Reserved</div>
    </div>
    </div>
  )
}

export default Sidebar
