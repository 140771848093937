import React,{ useState , useRef  } from 'react'
import './Home.css'
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import Signup from '../../component/Signup/Signup';
import Filmmiking from '../../assets/images/filming.png'
import aiicon from '../../assets/images/Ai.png'
import Business from '../../assets/images/Business.png'
import Fineart from '../../assets/images/FineArt.png'
import Design from '../../assets/images/design.png'
import Programming  from '../../assets/images/programming.png'
import sport from '../../assets/images/sport.png'
import cooking from '../../assets/images/cook.png'
import Wellness from '../../assets/images/wellness.png'
import animals from '../../assets/images/animal.png'
import Hash from '../../assets/images/hash.png'
import Course from '../../component/Course/Course';
import LeftArrow from '../../assets/images/leftarrow.png'
import RightArrow from '../../assets/images/rightarrow.png'
import StartIcon from '../../assets/images/StarReview.png'
import QouteIcon from '../../assets/images/quote.png'
//import ArrowUp from '../../assets/images/Scrollup.png'
//import LndexLogo from '../../assets/images/lndex logo.png'
//import Line from '../../assets/images/Line.svg'
//import FacebookIcon from '../../assets/images/facebookicon.svg'
//import YoutubeIcon from '../../assets/images/youtubeicon.svg'
//import InstgramIcon from '../../assets/images/instgramicon.svg'
import Instructor from '../../component/Instructor/Instructor';
import Article from '../../component/Article/Article';
import Login from '../../component/Login/Login';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext'
import FooterAR from '../../component/FooterArabic/FooterAr';
import Footer from '../../component/Footer/Footer'
import UnauthAppbarAR from '../../component/AppbarUnAuth/UnauthAppbarAR';
//import Signup from '../../component/Signup/Signup'
import HomePanner from '../../assets/images/homepanner.svg'
const Home = () => {
    // this from context api to manage language 
    const { language } = useLanguage();
    console.log(language)
      /*
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      */
      // to show Signup when click button 
    const [showSignup, setShowSignup] = useState(false);
      // to show Login when click button 
    const [showLogin, setShowLogin] = useState(false);
    const courseContainerRef = useRef(null);
    const scrollLeft = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollRight = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
    const instructorContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrolllLeft, setScrolllLeft] = useState(0);
    const scrollInstructorLeft = () => {
        if (instructorContainerRef.current) {
            instructorContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollInstructorRight = () => {
        if (instructorContainerRef.current) {
            instructorContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
     // about instructor scroll dragble   
     const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - instructorContainerRef.current.offsetLeft);
        setScrolllLeft(instructorContainerRef.current.scrollLeft);
    };
    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - instructorContainerRef.current.offsetLeft;
        const walk = (x - startX) * 1; // Adjust scroll speed multiplier here
        instructorContainerRef.current.scrollLeft = scrolllLeft - walk;
    };
    const handleMouseUp = () => {
        setIsDragging(false);
    };


    // about Article Refrence 
    const ArticleContainerRef = useRef(null);
    const scrollArticleLeft = () => {
        if (ArticleContainerRef.current) {
            ArticleContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollArticleRight = () => {
        if (ArticleContainerRef.current) {
            ArticleContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
  return (
    <div>
        {/* 
         {language === 'en' ?
  <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
            : 
  <UnauthAppbarAR showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
        }
      
        {showSignup ? <div className='Signup_home'><Signup setShowSignup={setShowSignup}/></div> : ''}
        {showLogin ?  <div className='Signup_home'><Login setShowLogin={setShowLogin}/> </div> : ''}
        */}
        <div className='Home_container'>
            <div className='Home_Sec1'>
                <img src={HomePanner} alt='HomePanner' className='Home_Sec1_Panner'/>
                <div className='Home_Sec1_title'>
                    <h2 className='Home_Sec1_title_h1'>“Your learning journey starts here”</h2>
                    <p className='Home_Sec1_title_p'>Pretium laoreet mi pulvinar hendrerit elementum ultricies. Risus aliquam amet ut ornare in bibendum urna adipiscing.</p>
                </div>
            </div>
        {/* Category hash SEction  */}
        <div className='Home_Sec2'>
           <div className='Home_Sec2_part1'>Explore by category</div>
           <div className='Home_Sec2_part2'>
              <div className='Home_Sec2_part2_items'><img src={Filmmiking} alt='Filmmiking'/>filmmaking & photography</div>
              <div className='Home_Sec2_part2_items'><img src={aiicon} alt='aiicon'/>Artificial intelligence</div>
              <div className='Home_Sec2_part2_items'><img src={Business} alt='Business'/>Business</div>
              <div className='Home_Sec2_part2_items'><img src={Fineart} alt='Fineart'/>fine Arts</div>
              <div className='Home_Sec2_part2_items'><img src={Design} alt='Design'/>Design</div>
              <div className='Home_Sec2_part2_items'><img src={Programming} alt='Programming'/>Programing</div>
              <div className='Home_Sec2_part2_items'><img src={sport} alt='sport'/>Sports</div>
              <div className='Home_Sec2_part2_items'><img src={cooking} alt='cooking'/>Cooking</div>
              <div className='Home_Sec2_part2_items'><img src={Wellness} alt='Wellness'/>Wellness</div>
              <div className='Home_Sec2_part2_items'><img src={animals} alt='animals'/>Animal husbandry</div>
              <div className='Home_Sec2_part2_icon'><img src={Hash} alt='Hash'/></div>
           </div>
        </div>
        {/*  The Course Header  */}
        <div className='Home_Sec3'>
            <div className='Home_Sec3_Part1'>New Courses</div>
            <div className='Home_Sec3_Part2'>
            <img src={LeftArrow} alt='ArrowLeft' onClick={scrollLeft}  />
            <img src={RightArrow} alt='ArrowRight' onClick={scrollRight}  />
            </div>
        </div>
           {/*  The Course Body   */}
           <div className='Home_Sec4' ref={courseContainerRef}>
             <Course />
             <Course />
           </div>
            {/* End The Course Body   */}
            <div className='Home_Sec3'>
            <div className='Home_Sec3_Part1'>Top instructors</div>
            <div className='Home_Sec3_Part2'>
            <img src={LeftArrow} alt='ArrowLeft'   onClick={scrollInstructorLeft}/>
            <img src={RightArrow} alt='ArrowRight'  onClick={scrollInstructorRight} />
            </div>
        </div>
             {/*  The Instructor Body   */}
             <div className='Home_Sec5' 
                 ref={instructorContainerRef}
                 onMouseDown={handleMouseDown}
                 onMouseMove={handleMouseMove}
                 onMouseUp={handleMouseUp}
                 onMouseLeave={handleMouseUp} // End drag if mouse leaves container
                 style={{ overflowX: 'scroll', cursor: isDragging ? 'grabbing' : 'grab' }}
             >
                <Instructor />
             </div>
               {/* End The Instructor Body   */}
               <div className='Home_Sec3'>
               <div className='Home_Sec3_Part1'>New articles</div>
               <div className='Home_Sec3_Part2'>
               <img src={LeftArrow} alt='ArrowLeft' onClick={scrollArticleLeft} />
               <img src={RightArrow} alt='ArrowRight' onClick={scrollArticleRight} />
               </div>
           </div>
             {/*  The Articles Body   */}
             <div className='Home_Sec6' ref={ArticleContainerRef}>
                <Article /> 
             </div>
               {/* End The Articles Body   */}
               {/* strat section 7 about Student review  */}
               <div className='Home_Sec7'>
                   <div className='Home_Sec7_Part1'>Why Students Love Lndex</div>
                   <div className='Home_Sec7_Part2_Container'>
                       <div className='Home_Sec7_Part2_Container_Text'>
                       "I was hesitant to take an offline course at first, but I'm so glad I did! The instructor was knowledgeable and engaging, and the small class size made it easy to get help when I needed it. I learned so much in the course, I would definitely recommend this platform to anyone who is looking for a high-quality offline course."
                       </div>
                       <div className='Home_Sec7_Part2_Container_Star'>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                       </div>
                      <div className='Home_Sec7_Part2_Container_Student'>
                          <div className='Home_Sec7_Part2_Container_Student_Name'>Muhammed Morsi</div>
                          <div className='Home_Sec7_Part2_Container_Student_Role'>student</div>
                      </div>
                      <div className='QouteIcon'>
                      <img src={QouteIcon} alt='QouteIcon' />
                    </div>
                   </div>
                   {/* Second Review  */}
                   <div className='Home_Sec7_Part2_Container'>
                   <div className='Home_Sec7_Part2_Container_Text'>
                   "I took an offline course on web development from lndex website. The instructor was a certified web developer with over 10 years of experience. He was very knowledgeable and engaging, and he made the material easy to understand. The class size was small, so I got a lot of individual attention. I would definitely recommend this platform to anyone who is interested in learning web development."
                   </div>
                   <div className='Home_Sec7_Part2_Container_Star'>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                   </div>
                  <div className='Home_Sec7_Part2_Container_Student'>
                      <div className='Home_Sec7_Part2_Container_Student_Name'>Ahmed hossam</div>
                      <div className='Home_Sec7_Part2_Container_Student_Role'>student</div>
                  </div>
                  <div className='QouteIcon'>
                    <img src={QouteIcon} alt='QouteIcon' />
                  </div>
               </div>
               </div>
            
               {/* 
               <div className='Home_Sec8_Footer'>
                      <div className='Home_Sec8_Footer_Sec1' onClick={scrollToTop}>Back to top page <img src={ArrowUp} alt='ArrowUp' onClick={scrollToTop}/></div>
                       <div className='Line'><img src={Line} alt='Line'/></div>
                      <div className='Home_Sec8_Footer_Sec2'>
                           <img src={LndexLogo} alt='LndexLogo'/>
                      </div>
                      <div className='Home_Sec8_Footer_Sec3'>
                       <ul>
                          <li>About Lndex</li>
                          <li>Contact us</li>
                          <li>Help & Support</li>
                          <li>Register as a instructor</li>
                          <li>terms</li>
                          <li>Privacy policy</li>
                       </ul>
                      </div>
                      <div className='Home_Sec8_Footer_Sec4'>
                          <img src={FacebookIcon} alt='FacebookIcon'/>
                          <img src={YoutubeIcon} alt='youtubeIcon'/>
                          <img src={InstgramIcon} alt='insta'/>
                      </div>
                      <div className='Line'><img src={Line} alt='Line'/></div>
                      <div className='Home_Sec8_Footer_Sec5'>
                      @Copyright Lndex All rights Reserved
                      </div>
               </div>
              */}
        </div> {/* End Home Container */}
         {/* 
        <div className='Home_Sec8_Footer'>
               {language === 'en' ? <Footer /> : <FooterAR />}
               </div>
                 Start Sec 8 === Footer Section   */}
            
    </div>
  )
}
export default Home
