import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Instructor.css';
import InstructorIcon from '../../assets/images/instructors/instructor1.png'; // Default placeholder
import StarIcon from '../../assets/images/Course/Star.png';
import { fetchInstructors } from '../../store/features/Getallinstructor';
import { followInstructor, unfollowInstructor } from '../../store/userFeature/userFollowInstructorSlice'; // استيراد المتابعة وإلغاء المتابعة
import Cookies from 'js-cookie'; // Import js-cookie to manage cookies
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate

const Instructor = () => {
  const dispatch = useDispatch();
  
  // Local state for popup visibility
  const [showPopup, setShowPopup] = useState(false);
  const [followStatus, setFollowStatus] = useState({});
  const navigate = useNavigate(); // Initialize useNavigate
  
  // Selecting necessary state from Redux store
  const instructors = useSelector((state) => state.instructors.list || []); // Default to an empty array
  const status = useSelector((state) => state.instructors.status);
  const error = useSelector((state) => state.instructors.error);
  
  // Fetch instructors on component mount
  useEffect(() => {
    dispatch(fetchInstructors());
  }, [dispatch]);
  
  // Function to handle follow button click
  const handleFollow = (e, instructorId) => {
    e.stopPropagation(); // منع انتشار الحدث إلى دالة الضغط على البطاقة
    // Check if the authToken exists in cookies
    const authToken = Cookies.get('authToken');

    if (!authToken) {
      setShowPopup(true); // Show popup if authToken is not found
    } else {
      console.log(`Followed instructor with ID: ${instructorId}`);

      // Toggle follow/unfollow based on current status
      const isFollowing = followStatus[instructorId];
      
      // Dispatch follow or unfollow action
      if (isFollowing) {
        dispatch(unfollowInstructor(instructorId)); // Dispatch unfollow action
      } else {
        dispatch(followInstructor(instructorId)); // Dispatch follow action
      }

      // Update local follow status after dispatch
      setFollowStatus((prevStatus) => ({
        ...prevStatus,
        [instructorId]: !isFollowing,
      }));
    }
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  const handleCardClick = (instructor) => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      navigate(`/instructor/${instructor.id}`, { state: { instructor } });
    } else {
      setShowPopup(true);
    }
  };

  return (
    <div style={{cursor:'pointer'}} className='Instructor_List_Container'>
      {/* Display loading, error, or instructor list based on the status */}
      {status === 'loading' && <p>Loading instructors...</p>}
      {status === 'failed' && <p>Error: {error}</p>}
      
      {status === 'succeeded' && instructors.length > 0 ? (
        instructors.map((instructor) => (
          <div 
            className='Instructor_Container' 
            key={instructor.id}  
            onClick={() => handleCardClick(instructor)} // Card click handler for navigation
          >
            {/* First Section: Instructor Image */}
            <div className='Instructor_Sec1'>
              <img 
                src={instructor.profile_image || InstructorIcon}
                className='Instructor_Profile_Picture' 
                alt={`${instructor.name || 'Instructor'}'s icon`} // Fallback name for better accessibility
              />
            </div>
            
            {/* Second Section: Instructor's Name */}
            <div className='Instructor_Sec2'>
              {instructor.name || 'No Name Provided'}
            </div>
            
            {/* Third Section: Job Title */}
            <div className='Instructor_Sec3'>
              {instructor.career || 'Lndex Instructor'}
            </div>
            
            {/* Fourth Section: Rating */}
            <div className='Instructor_Sec4'>
              <img src={StarIcon} alt='Star' />
              <div>{instructor.rating ? `${instructor.rating}%` : 'N/A'} ({instructor.reviews || 0} reviews)</div>
            </div>
            
            {/* Fifth Section: Follow Button */}
            <div className= 'Instructor_Sec5'>
              <button 
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the card click handler from firing
                  handleFollow(e, instructor.id); // Trigger the follow functionality
                }} 
                disabled={status === 'loading'} // Disable the button while loading
              >
                {status === 'loading' ? 'Loading...' : (followStatus[instructor.id] ? <span style={{color:'#D3D3D3'}}>Unfollow</span> : 'Follow')}
              </button>
            </div>
          </div>
        ))
      ) : (
        status === 'failed' && <p>No instructors found.</p>
      )}

      {/* Popup for login/signup message */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
          <div> <span className="close" onClick={closePopup}>&times;</span></div> 
            <div><p className='Popup_Message'>this feature is available only to registered users. Please sign in or create an account to unlock full access.</p></div> 
            <div className='popup_button_container'> 
              <Link className='link' to='/Login'>
                <div><button className='popup_button_Login'>login</button></div> 
              </Link>
              <Link className='link' to='/Signup'>
                <div><button className='popup_button_Signup'>sign up</button></div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Instructor;
