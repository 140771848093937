import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import ArtImage from '../../assets/images/ArtImage.svg'
import Loading from '../../component/Loading/Loading' 

const ArticleDetails = () => {
  const { id } = useParams(); // Get article ID from URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const authToken = Cookies.get('authToken');

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        const response = await axios.get(`https://dash.lndex.io/api/articale/${id}`, {
          headers: {
            token: authToken,
          },
        });
        console.log('Article details response:', response.data); // Inspect response data
        setArticle(response.data); // Set article data
      } catch (error) {
        console.error('Error fetching article details:', error);
      } finally {
        setLoading(false); // Ensure loading stops after the request
      }
    };

    fetchArticleDetails();
  }, [id, authToken]);

  if (loading) {
    return <Loading />; // Show loading message while fetching
  }

  if (!article) {
    return <div>Article not found</div>; // Handle case where no article is found
  }
  console.log('test',article)
  return (
    <div> 
    <div className='ArticleById_Container'> 
        <div className='ArticleById_Sec1'>
           <div className='ArticleById_Header'>{article.data.article.title}</div>
        </div>
        {/* Style Card */}
        <div className='ArticleById_Sec2'>
             <div><img src={article.data.article.instructor.profile_image} alt='Articler' style={{width:'50px',borderRadius:'50%'}}/></div>
             {/* Another Title and name */}
             <div>
                 <div className='ArticleById_Sec2_Name'>{article.data.article.instructor.name}</div>
                 <div className='ArticleById_Sec2_Date'>{article.data.article.publish_date}</div>
             </div>
        </div>
            {/* Style Section Three  */}
            <div className='ArticleById_Sec3'><img src={article.data.article.image} alt='ArtImage' className='Article_Image_From_Api' /></div>
                {/* Style Section Four  */}
                <div className='ArticleById_Sec4'>
                    <div className='ArticleById_Sec4_Title'>
                      
                     </div>
                     <div className='ArticleById_Sec4_Paraghraph'>
                     {article.data.article.description}
                     </div>
                     <div></div>
              </div>
   {/* close Container */} </div> 
          {/* Out Container  */}
     
   </div>
  );
};

export default ArticleDetails;
