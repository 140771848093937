import React from 'react'
import './InstructorAbout.css'
import LineHight from '../../assets/images/instructors/lineHight.svg'
//import AboutImage  from '../../assets/images/instructors/About.svg'
const InstructorAbout = ({instructorAbout})=>{
 return (
    <div className="InstructorAbout_Container">
         {/* Start Section One  */}
         <div className='InstructorAbout_Sec1'>
           About me
         </div>
                {/* Start Section 2  */}
         <div className='InstructorAbout_Sec2'>
                {/* sec 2 part 1 */}
              <div className='InstructorAbout_Sec2_Part1'>
                   <div className='InstructorAbout_Sec2_Part1_ParghraphOne' >
                     {instructorAbout}
                   </div>
                   <div className='InstructorAbout_Sec2_Part1_ParghraphOne' >
                   Ahmed Zayed is a passionate and experienced instructor on the learning platform. He has over 10 years of experience teaching a variety of subjects, including computer science, mathematics, and physics. He is known for his clear and concise teaching style, and his ability to make complex concepts easy to understand.
                   </div>
                   <div className='InstructorAbout_Sec2_Part1_ParghraphOne' >
                   achievement
                   </div>
                   <div className='InstructorAbout_Sec2_Part1_ParghraphOne' >
                   Ahmed was recently awarded the "Instructor of the Year" award by the learning platform. He was recognized for his dedication to his students and his commitment to providing a high-quality learning experience.
                   </div>
              </div>
                  <img src={LineHight} alt='LineHight'/>
                {/* sec 2 part 2 
                      <div className='InstructorAbout_Sec2_Part2'><img src={AboutImage} alt='AboutImage' /></div>  
                */}
         </div>
    </div>
 )
}
export default InstructorAbout ; 