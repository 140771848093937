import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorArticlesById } from '../../store/userFeature/showArticlesByInstructorSlise'; // import the correct Redux action
import Loading from '../Loading/Loading';
import Instructo from '../../assets/images/instructors/hakim.jpg';

const InstructorArticle = ({ instructorId, wrap }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  /// Access articles and status from Redux store
  const { articles, status, error } = useSelector((state) => state.instructorArticleById);

  useEffect(() => {
    dispatch(fetchInstructorArticlesById(instructorId)); // Replace '2' with the desired instructor ID
  }, [dispatch , instructorId]);

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const truncateDescription = (description, limit = 60) =>
    description.length > limit ? description.slice(0, limit) + 'Read more' : description;

  const truncateTitle = (title, length = 25) =>
    title.length <= length ? title : title.substring(0, length) + '...';

  return (
    <div className='Article_container'>
        {status === 'failed' && <p>Failed to load articles: {error}</p>}
      {status === 'loading' ? (
        <Loading />
      ) : (
        <div className={wrap ? 'Article_flex_container_WithWrap' : 'Article_flex_container'}>
          {articles.map((article) => (
            <div 
              key={article.id} 
              className='Article_card' 
              onClick={() => handleArticleClick(article.id)}
            >
              <div className='Article_Sec1'>
                <img className='Article_Sec1' src={article.image} alt='ArticleImg' />
              </div>
              <div className='Article_Sec2'>5-10 mins to read</div>
              <div className='Article_Sec3'>
                {truncateTitle(article.title)}
              </div>
              <div className='Article_Sec4'>
               
                <div 
                  className="read-more" 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleArticleClick(article.id);
                  }}
                >
                   {truncateDescription(article.description)}
                </div>
              </div>
              <div className='Article_Sec5'>
                <div className='Author_information'>
                  <div className='Article_Sec5_Part1'>
                    <img
                      className='author_icon'
                      src={article.instructor.profile_image || Instructo} 
                      alt='AuthorImage'
                    />
                  </div>
                  <div className='Article_Sec5_Part2'>
                    <div className='Article_Sec5_Part2_name'>{article.instructor.name}</div>
                    <div className='Article_Sec5_Part2_date'>{new Date(article.publish_date).toLocaleDateString()}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InstructorArticle;
