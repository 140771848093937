import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../config'; 
import Cookies from 'js-cookie';

// Async thunk to fetch saved courses
export const fetchUserSavedCourses = createAsyncThunk(
  'user/fetchUserSavedCourses',
  async (_, { rejectWithValue }) => {
    const token = Cookies.get('authToken');
    
    if (!token) {
      return rejectWithValue('No authentication token found');
    }

    try {
      const response = await axios.get(`${URL}/user-courses-saved`, {
        headers: {
          token: token, 
        },
      });
      return response.data.data.courses; // Returning the courses data
    } catch (error) {
      console.error('Error fetching saved courses:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch saved courses');
    }
  }
);

// Slice definition
const fetchSavedCoursesSlice = createSlice({
    name: 'fetchUserSavedCourses', // Make sure this matches the slice name you're using
    initialState: {
      courses: [], // Make sure courses is initialized as an empty array
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUserSavedCourses.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchUserSavedCourses.fulfilled, (state, action) => {
          state.loading = false;
          state.courses = action.payload; // Store the fetched courses
        })
        .addCase(fetchUserSavedCourses.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; // Set error message if fetching fails
        });
    },
  });
  

export default fetchSavedCoursesSlice.reducer;
